<template>
	<div
		v-auto-animate="$animate"
		style="float: left; white-space: nowrap; display: flex">
		<div class="xcommentbox" v-if="!showComments" @click="toggleComments()">
			<i class="icon-pencil-alt"></i> Write a comment...
		</div>
		<div class="xcommentbox-expand" @click="toggleComments()">
			<div v-if="!showComments">
				<span>{{ commentData.length }}</span> Comments
				<i class="icon-down-open-big"></i>
			</div>
			<div v-else>Hide Comments <i class="icon-up-open-big"></i></div>
		</div>
	</div>
	<div class="clear"></div>
	<div v-auto-animate="$animate">
		<div v-if="showComments" class="xcommentbox-main">
			<form
				@submit.prevent="saveComment()"
				style="padding-bottom: 20px"
				v-auto-animate="$animate">
				<h3 class="smallcapitals">
					<i class="icon-pencil-alt" style="margin-right: 5px"></i> Write a
					Comment:
				</h3>
				<textarea
					v-model="newComment"
					ref="focusComment"
					maxlength="1000"></textarea>
				<input
					type="submit"
					v-if="newComment.length > 0"
					style="margin-top: 5px" />
			</form>
			<h3 class="smallcapitals">
				Comments (<span>{{ commentData.length }}</span
				>)
			</h3>
			<div
				class="xcomments"
				v-auto-animate="$animate"
				v-if="commentData"
				style="padding-bottom: 20px">
				<div
					class="xcommentbox-main-item"
					v-for="(comment, index) in commentData"
					:key="index">
					<div
						class="xusericon"
						data-size="medium"
						style="float: left; display: block; margin-right: 10px">
						<div class="xusericon-picture">
							{{ getInitials(comment.userDisplayName) }}
						</div>
						<div class="xusericon-gender" data-gender=""></div>
					</div>
					<div class="xcomments-content">
						<h4>{{ decodeHTMLEncodedStr(comment.userDisplayName) || 'No Name' }}</h4>
						<p>{{ decodeHTMLEncodedStr(comment.comment) }}</p>
						<h5>
							{{
								$moment(`${comment.createdAtUtc}Z`)
									.tz(localTimezone)
									.format('MMMM D, YYYY [at] h:mm A z')
							}}
						</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { defineProps } from '@vue/runtime-core';
	import axios from 'axios';
	import store from '../store';
	import functions from '@/use/functions.js';
	import useFunctions from '@/use/functions1';

	var props = defineProps(['id', 'type']);
	var commentData = $ref([]);
	var showComments = $ref(false);
	var newComment = $ref('');
	var focusComment = $ref(null);

	const { decodeHTMLEncodedStr } = useFunctions();

	async function getCommentsById(id, type) {
		let requestUrl = `/api/comments/draw/${id}`;

		if (type == 'loan') {
			requestUrl = `/api/comments/loan/${id}`;
		} else if (type == 'draw') {
			requestUrl = `/api/comments/draw/${id}`;
		}

		await axios(requestUrl, { require: 'json' })
			.then((response) => {
				// sort comments by date (newest first)
				response.data.sort((a, b) =>
					a.createdAtUtc < b.createdAtUtc ? 1 : -1
				);

				// if the response is an array and contains at least 1 comment
				if (Array.isArray(response.data) && response.data.length >= 1) {
					response.data.forEach((comment) => {
						if (type == 'draw') {
							comment.type = 'School Portal';
						}
					
						// decode the encoded text (for example: &amp; becomes & and &quot; becomes ")
						var parser = new DOMParser();
						var decodedComment = parser.parseFromString(
							`<!doctype html><body>${comment.comment}</body>`,
							'text/html'
						).body.textContent;
						comment.comment = decodedComment;

						// the loan comment dates use the key `createdAtUtc` and the application comment dates use the key `lastChangedAt`
						comment.date = comment.lastChangedAt || comment.createdAtUtc;
					});					
					// set the commentData to the response
					commentData = commentData.concat(response.data);
					
					// commentData = response.data;
					if (type == 'loan' || type == 'draw') {
						// sort comments by date (newest first)
						commentData.sort((a, b) =>
							a.createdAtUtc < b.createdAtUtc ? 1 : -1
						);
					}
				}
			})
			.catch((error) => {
				console.log(`API Failed: /api/comments/draw/${id}`, error);
			});
	}

	async function saveComment() {
		var jsonData = {
			drawId: props.id,
			userDisplayName: `${store.state.oidcStore.user.given_name} ${store.state.oidcStore.user.family_name}`,
			comment: newComment,
		};

		// validation: require the comment value to not be empty, or else give a message
		if (!jsonData.comment) {
			functions.openModal('Please enter a comment before submitting.');
			return;
		}

		await axios({
			url: `/api/comments/draw`,
			method: 'POST',
			require: 'json',
			data: jsonData,
		})
			.then(() => {
				functions.openModal('Your comment has been added!');

				// push the comment into the commentData array so the user can see it on the screen
				commentData.unshift(jsonData);

				// clear the textbox
				newComment = '';
			})
			.catch((error) => {
				console.log(`API Error: /api/applications/comments`, error);
				functions.openModal('Error: Comment was not saved!');
			});
	}

	function toggleComments() {
		// toggle showComments true and false
		showComments = !showComments;

		// delay 500ms so the animation can finish before the focus
		setTimeout(() => {
			focusComment.focus();
		}, 500);
	}

	function getInitials(input) {
		if (input) {
			// split each name into an array on space, map each name to only the first letter, join the letters together, return only two letters maximum
			return input
				.split(' ')
				.map((name) => name[0])
				.join('')
				.substr(0, 2);
		} else {
			// return "NN" for no name
			return 'NN';
		}
	}

	getCommentsById(props.id);
</script>

<style scoped></style>
