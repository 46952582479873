<template>
	<Loader v-if="loading" />
	<div>
		<Page>
			<div class="xpage-breadcrumbs">
				<router-link to="/dashboard" class="icon-home"></router-link>
				<span>></span>
				<router-link :to="`/${pageName.toLowerCase()}`">
					{{ pageName }}
				</router-link>
			</div>
			<div class="xpage-inner">
				<h2>{{ pageName }}</h2>

				<!-- Search Bar -->
				<div class="xpage-searchbar">
					<div class="xsearchbox">
						<div class="xsearchbox-field">
							<input
								type="text"
								v-model.trim="applications.search.typing"
								@focus="applications.search.focus = true"
								@blur="blurSearchbox"
								@keyup="searchDropdown(applications.search.typing)"
								@keyup.enter="
									triggerSearch(applications.search.typing);
									$event.target.blur();
								"
								:placeholder="`Search by First Name, LastName, SSN, CLUID ...`"
							/>
							<i
								class="icon-cancel"
								v-if="applications.search.typing.length > 0"
								@click="
									clearSearchbox();
									triggerSearch(null, 1);
								"
							></i>
						</div>
						<div class="xsearchbox-submit">
							<button
								@click="triggerSearch(applications.search.typing, 1)"
								class="icon-search"
							>
								<span>Search</span>
							</button>
						</div>
					</div>
					<div class="page-size-selector">
						<select v-model="pageSize" @change="changePageSize">
							<option value="" disabled></option>
							<option value="30">30</option>
							<option value="60">60</option>
							<option value="90">90</option>
						</select>
					</div>
				</div>

				<!-- Filters and Table Data -->
				<div class="xpage-content-wrapper">
					<!-- Filters -->
					<div class="filters xpage-search-filters">
						<div class="xpage-search-filter-header">
							<div class="xpage-search-filter-title table-title">
								<h3>Search Filters</h3>
							</div>
						</div>
						<div class="xpage-search-filter-content">
							<!-- Enrollment Term Filter -->
							<div class="xpage-search-filter-section">
								<h3>Enrollment Term</h3>
								<div class="xpage-search-filter-section-content">
									<label
										v-for="term in enrollmentTerms"
										:key="term.id"
										class="xcheck"
									>
										{{ term.description }}
										<input
											type="checkbox"
											:value="term.id"
											v-model="selectedEnrollmentTerms"
										/>
										<span></span>
									</label>
								</div>
								<div style="display: flex; justify-content: center">
									<button
										class="clearButton"
										@click="clearSelection('enrollmentTerms')"
									>
										Clear & Reset
									</button>
								</div>
							</div>
							<!-- Disbursement Method Filter -->
							<div class="xpage-search-filter-section">
								<h3>Disbursement Method</h3>
								<div class="xpage-search-filter-section-content">
									<label
										v-for="method in disbursementMethods"
										:key="method.id"
										class="xcheck"
									>
										{{ method.name }}
										<input
											type="checkbox"
											:value="method.id"
											v-model="selectedDisbursementMethods"
										/>
										<span></span>
									</label>
								</div>
								<div style="display: flex; justify-content: center">
									<button
										class="clearButton"
										@click="clearSelection('disbursementMethods')"
									>
										Clear & Reset
									</button>
								</div>
							</div>
							<!-- Disbursement Status Filter -->
							<div class="xpage-search-filter-section">
								<h3>Disbursement Status</h3>
								<div class="xpage-search-filter-section-content">
									<label
										v-for="status in disbursementStatuses"
										:key="status.id"
										class="xcheck"
									>
										{{ status.name }}
										<input
											type="checkbox"
											:value="status.id"
											v-model="selectedDisbursementStatuses"
										/>
										<span></span>
									</label>
								</div>
								<div style="display: flex; justify-content: center">
									<button
										class="clearButton"
										@click="clearSelection('disbursementStatuses')"
									>
										Clear & Reset
									</button>
								</div>
							</div>
						</div>
					</div>
					<!-- Table Data -->
					<div class="xpage-search-results table-data">
						<div>
							<div v-if="typeof filteredAndSortedApplications === 'string'">
								{{ filteredAndSortedApplications }}
							</div>
							<div
								v-if="filteredAndSortedApplications.length > 0"
								class="results-info"
							>
								Found {{ filteredAndSortedApplications.length }} results
								<span class="results-info-pages">
									Page {{ currentPage }} of {{ totalPageCountText }}
								</span>
								<!-- Pagination -->
								<div v-if="totalPages > 1" class="pagination">
									<div class="pagination-controls">
										<button
											@click="changePage(currentPage - 1)"
											:disabled="currentPage <= 1"
										>
											&lt;
										</button>
										<button
											v-for="n in totalPages"
											:key="n"
											:class="{ xclicked: n === currentPage }"
											@click="changePage(n)"
										>
											{{ n }}
										</button>
										<button
											@click="changePage(currentPage + 1)"
											:disabled="currentPage >= totalPages"
										>
											&gt;
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="xpage-search-results-buttons">
							<div
								class="xfilter-button"
								v-if="applications.search.active"
								@click="
									clearSearchbox();
									triggerSearch(null, 1);
								"
							>
								{{ "Search: " + applications.search.text }}
							</div>
							<div
								class="xfilter-button"
								v-for="(item, index) in selectedFilters()"
								@click="clearFilter(item)"
								:key="index"
							>
								{{ item.name }}
							</div>
							<div
								class="xfilter-button"
								v-if="selectedFilters().length + applications.search.active > 1"
								@click="clearAllFiltersAndSearch()"
								data-type="clear"
							>
								Clear All
							</div>
						</div>
						<div
							class="xpage-search-results-data"
							v-auto-animate="{ duration: 100, ...$animate }"
						>
							<div
								class="b-table"
								style="margin-top: 5px"
								v-if="
									filteredAndSortedApplications.length > 0 &&
									typeof filteredAndSortedApplications !== 'string'
								"
								v-auto-animate="{ duration: 100, ...$animate }"
							>
								<div class="table-wrapper has-mobile-cards">
									<table
										class="table is-fullwidth is-hoverable is-fullwidth xstudentloan"
									>
										<thead>
											<tr>
												<th
													@click="sortData('disbursementStatusType.name')"
													class="table-header"
												>
													<div class="header-content">
														Status
														<span class="sorting-direction">
															<span
																v-if="
																	sorting.column === 'disbursementStatusType.name'
																"
															>
																{{ sorting.direction === "asc" ? "▲" : "▼" }}
															</span>
														</span>
													</div>
												</th>
												<th
													@click="sortData('transactionDate')"
													class="table-header"
												>
													<div class="header-content">
														Disbursement Date
														<span class="sorting-direction">
															<span v-if="sorting.column === 'transactionDate'">
																{{ sorting.direction === "asc" ? "▲" : "▼" }}
															</span>
														</span>
													</div>
												</th>
												<th
													@click="sortData('borrower.firstName')"
													class="table-header"
												>
													<div class="header-content">
														Student
														<span class="sorting-direction">
															<span
																v-if="sorting.column === 'borrower.firstName'"
															>
																{{ sorting.direction === "asc" ? "▲" : "▼" }}
															</span>
														</span>
													</div>
												</th>
												<th @click="sortData('lenderName')" class="table-header">
													<div class="header-content">
														Credit Union
														<span class="sorting-direction">
															<span v-if="sorting.column === 'lenderName'">
																{{ sorting.direction === "asc" ? "▲" : "▼" }}
															</span>
														</span>
													</div>
												</th>
												<th
													@click="sortData('enrollmentTerm.description')"
													class="table-header"
												>
													<div class="header-content">
														Term
														<span class="sorting-direction">
															<span
																v-if="
																	sorting.column === 'enrollmentTerm.description'
																"
															>
																{{ sorting.direction === "asc" ? "▲" : "▼" }}
															</span>
														</span>
													</div>
												</th>
												<th
													@click="sortData('borrower.nationalIdNumber')"
													class="table-header"
												>
													<div class="header-content">
														Tax ID (SSN or Other)
														<span class="sorting-direction">
															<span
																v-if="
																	sorting.column === 'borrower.nationalIdNumber'
																"
															>
																{{ sorting.direction === "asc" ? "▲" : "▼" }}
															</span>
														</span>
													</div>
												</th>

												<th
													@click="sortData('disbursedAmount')"
													class="table-header"
												>
													<div class="header-content">
														Requested Amount
														<span class="sorting-direction">
															<span v-if="sorting.column === 'disbursedAmount'">
																{{ sorting.direction === "asc" ? "▲" : "▼" }}
															</span>
														</span>
													</div>
												</th>
											</tr>
										</thead>
										<template
											v-for="(loan, index) in filteredAndSortedApplications"
											:key="index"
										>
											<tr @click="showDetails(loan, index)">
												<td
													data-label="Status"
													:style="
														getStatusStyle(loan.disbursementStatusType.name)
													"
												>
													{{
														loan.disbursementStatusType?.name ||
														"No Current Status"
													}}
												</td>
												<td
													data-label="Disbursement Date"
													style="font-weight: 700"
												>
													{{
														$moment(loan.transactionDate)
															.tz(localTimezone)
															.format("MM/DD/YYYY")
													}}
												</td>
												<td data-label="Student">
													<div
														class="xusericon"
														data-size="small"
														style="float: left"
													>
														<div class="xusericon-picture">
															{{ loan.initials }}
														</div>
														<div class="xusericon-gender" data-xgender=""></div>
													</div>
													<p
														style="
															margin: 0px;
															font-weight: 700;
															font-size: 16px;
															line-height: 36px;
														"
													>
														{{
															loan.borrower
																? `${decodeHTMLEncodedStr(
																		loan.borrower.firstName
																	)} ${decodeHTMLEncodedStr(
																		loan.borrower.lastName
																	)}`
																: "—"
														}}
													</p>
												</td>
												<td data-label="Credit Union" style="font-weight: 700">
													{{ decodeHTMLEncodedStr(loan.lenderName) }}
												</td>
												<td data-label="Term" style="font-weight: 700">
													{{ loan.enrollmentTerm.description }}
												</td>
												<td
													data-label="NationalIdNumber"
													style="font-weight: 700"
												>
													{{
														loan.borrower && loan.borrower.nationalIdNumber
															? `${loan.borrower.nationalIdNumber}`
															: "—"
													}}
												</td>
												<td
													data-label="Requested Amount"
													style="font-weight: 700"
												>
													${{
														loan.disbursedAmount?.toLocaleString("en-US", {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}) || ".00"
													}}
												</td>
											</tr>
											<tr v-if="loan.showDetails" class="detail">
												<td colspan="8" style="background: #f4f5f9">
													<div style="padding: 35px; padding-bottom: 0">
														<comments type="draw" :id="loan.drawId"></comments>
													</div>
													<div class="xstudentloan-details">
														<form
															@submit.prevent="confirmApplication()"
															autocomplete="off"
														>
															<fieldset>
																<div>
																	<div class="xfields">
																		<div class="xfield">
																			<label>
																				Amount
																				<i>*</i>
																			</label>
																			<input
																				type="text"
																				v-model="loan.disbursedAmount"
																				required
																			/>
																		</div>
																		<div class="xfield">
																			<label>Hold</label>
																			<div>
																				<input
																					type="checkbox"
																					v-model="
																						loan.disbursementStatusCheckbox
																					"
																					@change="
																						disbursementCheckbox('checkbox', loan)
																					"
																				/>
																			</div>
																		</div>
																		<div class="xfield"></div>
																	</div>
																	<!-- post disb change -->
																	<div
																		v-if="loan.disbursementStatusTypeId === 7"
																		class="disbursement-section post-change-border"
																	>
																		<PostDisbursementChangeForm
																			:disbursement="loan"
																		/>
																	</div>
																	<div v-if="isAdmin">
																		<h3 class="disbursement-section-header">
																			Admin Settings
																		</h3>
																		<div class="xfields">
																			<div class="xfield">
																				<label>Disbursement Status</label>
																				<select
																					v-model="loan.disbursementStatusTypeId"
																					@change="
																						disbursementCheckbox('dropdown', loan)
																					"
																				>
																					<option
																						v-for="(
																							disbursementStatus,
																							disbursementStatusIndex
																						) in store.state.types[
																							'DisbursementStatusType'
																						].results"
																						:key="disbursementStatusIndex"
																						:value="disbursementStatus.id"
																					>
																						{{ disbursementStatus.name }}
																					</option>
																				</select>
																			</div>
																			<div class="xfield">
																				<label>Disbursement Status Reason</label>
																				<select
																					v-model="
																						loan.drawDisbursementStatusReasonTypeId
																					"
																				>
																					<option value="">
																						Select an option
																					</option>
																					<option
																						v-for="(
																							statusReason, statusReasonIndex
																						) in store.state.types[
																							'DrawDisbursementStatusReasonType'
																						].results"
																						:required="
																							loan.disbursementStatusTypeId === 3
																						"
																						:key="statusReasonIndex"
																						:value="statusReason.id"
																					>
																						{{ statusReason.name }}
																					</option>
																				</select>
																			</div>
																			<div class="xfield">
																				<label>
																					Disbursement Method
																					<i>*</i>
																				</label>
																				<select v-model="loan.disbursementTypeId">
																					<option
																						v-for="(
																							disbursementType,
																							disbursementTypeIndex
																						) in store.state.types[
																							'DisbursementType'
																						]"
																						:key="disbursementTypeIndex"
																						:value="disbursementType.id"
																					>
																						{{ disbursementType.name }}
																					</option>
																				</select>
																			</div>
																			<div class="xfield"></div>
																		</div>
																	</div>
																</div>
																<div style="max-width: 750px">
																	<button
																		class="xlarge"
																		data-color="green"
																		@click.prevent="
																			updateDisbursement(loan, 'confirmation')
																		"
																	>
																		Update Disbursement
																	</button>
																</div>
															</fieldset>
														</form>
													</div>
												</td>
											</tr>
										</template>
									</table>
								</div>
							</div>
							<div v-if="loading" class="xfoundnothing">
								<h2 style="padding-top: 5px; margin-bottom: 10px">Loading...</h2>
							</div>
							<div v-if="filteredAndSortedApplications.length === 0" class="xfoundnothing">
								<h2 style="padding-top: 5px; margin-bottom: 10px">
									No matches found
								</h2>
								<b>0</b>
								results
								<span v-if="applications.search.active">
									for
									<b>"{{ applications.search.text }}"</b>
								</span>
								<span v-if="selectedFilters('text').length > 0">
									with the filters
								</span>
								<b>
									<span>{{ selectedFilters("text") }}</span>
								</b>
							</div>
							<div
								v-if="
									applications.query.totalCount > 30 &&
									typeof applications.data !== 'string'
								"
								style="display: none"
								class="xpagnation"
							>
								<button
									v-for="number in Math.ceil(
										(applications.query.totalCount + pageSize - 1) / pageSize
									)"
									:key="number"
									:class="{
										xselected:
											number === applications.query.pageNumber ||
											(number === 1 && !applications.query.pageNumber),
									}"
									@click="
										triggerSearch(applications.search.typing, number, pageSize)
									"
								>
									{{ number }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Page>
	</div>
</template>

<script setup>
import { reactive, onMounted, watch, ref, computed } from "vue";
import Loader from "@/components/Loader.vue";
import Page from "@/views/layout/Page.vue";
import store from "@/store";
import { useRouter, useRoute } from "vue-router";
import functions from "@/use/functions.js";
import axios from "axios";
import comments from "@/components/Comments.vue";
import useFunctions from "@/use/functions1";
import PostDisbursementChangeForm from "@/components/disbursements/PostDisbursementChangeForm.vue";

const { decodeHTMLEncodedStr } = useFunctions();

const pageName = "Disbursements";
const isAdmin = store.state.oidcStore.user.school_access === "Yes";
const pageSize = ref(90);
const currentPage = ref(1);
const totalCount = ref(0);

const loading = ref(true);
const route = useRoute();
const router = useRouter();
const applications = reactive({
	data: [],
	dataDropdown: [],
	dropdownData: false,
	filterDisplay: false,
	filterOrder: 0,
	filters: [],
	layout: "boxes",
	modal: false,
	recentlyViewed: false,
	search: {
		text: "",
		type: "",
		typing: "",
		timestamp: 0,
		active: false,
		focus: false,
		error: false,
		dropdown: false,
	},
	query: {
		pageNumber: 1,
		totalCount: 0,
	},
});
const enrollmentTerms = ref([]);
const disbursementMethods = ref([]);
const disbursementStatuses = ref([]);
const selectedEnrollmentTerms = ref([]);
const selectedDisbursementMethods = ref([]);
const selectedDisbursementStatuses = ref([]);

const loadCachedDisbursementData = () => {
	const disbursementStatusData = functions.checkLocalStorage(
		"cache.DisbursementStatusType"
	);
	if (disbursementStatusData) {
		disbursementStatuses.value = disbursementStatusData.data.results;
	}
	const disbursementMethodData = functions.checkLocalStorage(
		"cache.DisbursementType"
	);
	if (disbursementMethodData) {
		disbursementMethods.value = disbursementMethodData.data;
	}
	const enrollmentTermData = functions.checkLocalStorage(
		"cache.EnrollmentTerms"
	);
	if (enrollmentTermData) {
		enrollmentTerms.value = enrollmentTermData.data;
	}
};

const filteredAndSortedApplications = computed(() => {
	if (!Array.isArray(applications.data) || applications.data.length === 0) {
		return [] || "No results found";
	}
	const uniqueDisbursements = [];
	const map = new Map();
	for (const item of applications.data) {
		if (!map.has(item.id)) {
			map.set(item.id, true);
			uniqueDisbursements.push(item);
		}
	}
	if (!sorting.column) {
		return uniqueDisbursements;
	}

	return [...uniqueDisbursements].sort((a, b) => {
		const modifier = sorting.direction === "asc" ? 1 : -1;
		let aValue = getNestedValue(a, sorting.column);
		let bValue = getNestedValue(b, sorting.column);

		if (typeof aValue === "number" && typeof bValue === "number") {
			aValue = parseFloat(aValue);
			bValue = parseFloat(bValue);
		} else if (typeof aValue === "string" && typeof bValue === "string") {
			aValue = aValue.toLowerCase();
			bValue = bValue.toLowerCase();
		}

		if (aValue < bValue) return -1 * modifier;
		if (aValue > bValue) return 1 * modifier;
		return 0;
	});
});

const totalPages = computed(() => {
	return Math.ceil(totalCount.value / pageSize.value);
});

const totalPageCountText = computed(() => {
	return `${totalPages.value} ${totalPages.value === 1 ? "page" : "pages"}`;
});

const changePage = (page) => {
	if (page >= 1 && page <= totalPages.value) {
		currentPage.value = page;
		applications.query.pageNumber = page;
		triggerSearch(applications.search.typing, page, pageSize.value);
	}
};

const changePageSize = () => {
	currentPage.value = 1;
	triggerSearch(applications.search.text, 1, pageSize.value);
};

const showDetails = (loan) => {
	loan.showDetails = !loan.showDetails;
};

function getStatusStyle(statusName) {
	return statusName
		? {
				background: statusName === "Active" ? "white" : "white",
				color: statusName === "Active" ? "black" : "black",
				fontWeight: 700,
		  }
		: {};
}

async function updateDisbursement(disbursement, confirmation) {
	if (confirmation) {
		var errors = [];

		if (!disbursement.disbursedAmount || (disbursement.disbursedAmount < 50 && disbursement.disbursementStatusTypeId !== 8)) {
			errors.push("• The disbursed amount must be at least $50");
		}

		if (
			disbursement.disbursementStatusTypeId === 3 &&
			!disbursement.drawDisbursementStatusReasonTypeId
		) {
			errors.push("• A reason is required when placing a disbursement on hold");
		}

		if (errors.length === 0) {
			functions.openModal({
				title: "Confirm",
				description: "Are you sure you want to update this disbursement?",
				buttons: [
					{ title: "Cancel", onClick: () => functions.closeModal() },
					{
						title: "Update",
						onClick: () => updateDisbursement(disbursement),
					},
				],
			});

			return;
		} else {
			errors = Array.from(new Set(errors));

			functions.openModal({
				title: "Alert",
				description: "Please resolve the following issues before updating:",
				html: `<p>${errors.join("<br/>")}</p>`,
			});
		}
	} else {
		functions.closeModal();
		const disbursementClone = JSON.parse(JSON.stringify(disbursement));

		delete disbursementClone.transactionDate;

		return await axios(`/api/loanservice/disbursements/update`, {
			require: "json",
			method: "POST",
			data: disbursementClone,
		})
			.then((response) => {
				functions.openModal({
					title: "Success",
					description: "Disbursement has been successfully updated.",
					buttons: [
						{
							title: "Close",
							onClick: () => {
								functions.closeModal();
								disbursement.showDetails = false;
							},
						},
					],
				});
			})
			.catch((error) => {
				functions.openModal({
					title: "Error",
					description:
						"There was an error updating this disbursement. Please try again.",
				});

				console.log(`API Error: /api/loanservice/disbursements/update`, error);
			});
	}
}

const triggerSearch = async (
	searchText,
	pageNumber = 1,
	size = pageSize.value
) => {
	if (searchText) {
		searchText = functions.cleanSearchText(searchText).toLowerCase();
		applications.search.typing = searchText;
		applications.search.text = searchText;
		applications.search.active = true;
	} else if (applications.search.active && applications.search.text) {
		searchText = functions
			.cleanSearchText(applications.search.text)
			.toLowerCase();
		applications.search.typing = searchText;
		applications.search.text = searchText;
	}

	if (pageNumber) {
		applications.query.pageNumber = pageNumber;
	}
	if (size) {
		pageSize.value = size;
	}

	searchApplications(searchText, pageNumber, size);
};


async function searchApplications(searchText, pageNumber, pageSize) {
	applications.data = "Loading...";
	applications.search.timestamp = Date.now();
	const filters = {
		disbursementTypeIds: selectedDisbursementMethods.value,
		disbursementStatusIds: selectedDisbursementStatuses.value,
		enrollmentTermIds: selectedEnrollmentTerms.value,
	};

	const response = await functions.getDisbursements(
		searchText,
		filters,
		pageNumber,
		pageSize
	);

	if (response) {
		applications.data = response.data.items;
		applications.query = {
			pageNumber: response.data.page.index,
			totalCount: response.data.totalCount,
		};
		totalCount.value = response.data.totalCount;
		currentPage.value = response.data.page.index;
	} else {
		applications.data = "Error Loading Results";
	}
}

const searchDropdown = async (searchText) => {
	searchText = functions.cleanSearchText(searchText).toLowerCase();
	applications.search.typing = searchText;
	applications.search.timestamp = Date.now();

	await functions
		.getDisbursements(searchText)
		.then((response) => {
			if (
				response &&
				response.config.postTime >= applications.search.timestamp
			) {
				applications.dataDropdown = response.data.data;
			}
		})
		.catch((error) => {
			applications.dataDropdown = false;
			console.log(error);
		});
};

const clearSearchbox = () => {
	applications.search.text = "";
	applications.search.typing = "";
	if (applications.search.active) {
		applications.search.active = false;
	}
};

const blurSearchbox = () => {
	setTimeout(() => {
		applications.search.focus = false;
	}, 200);
};

function clearFilter(item) {
	item.checked = false;

	if (item.children) {
		item.children.forEach((child) => {
			child.checked = false;
		});
	}

	triggerSearch(applications.search.text, 1);
}

const clearAllFiltersAndSearch = () => {
	router.push("");
};

const selectedFilters = (output) => {
	let data = [];

	applications.filters
		.filter((section) => section.type != "radio")
		.forEach((section) => {
			section.results.forEach((field) => {
				if (field.children && !field.checked) {
					field.children.forEach((child) => {
						if (child.checked) {
							child["section"] = section.description;
							data.push(child);
						}
					});
				} else if (field.checked) {
					field["section"] = section.description;
					data.push(field);
				}

				if (field.children) {
					field.checked = field.children.every((child) => child.checked);
				}
			});
		});

	data.sort((a, b) => {
		return a.filterOrder - b.filterOrder;
	});

	if (output == "text") {
		data = data
			.map((field) => {
				return `"${field.name}"`;
			})
			.join(" or ");
	}

	return data;
};

const clearSelection = (section) => {
	if (section === "enrollmentTerms") {
		selectedEnrollmentTerms.value = [];
	} else if (section === "disbursementMethods") {
		selectedDisbursementMethods.value = [];
	} else if (section === "disbursementStatuses") {
		selectedDisbursementStatuses.value = [];
	}

	triggerSearch(applications.search.text, 1);
};

function disbursementCheckbox(type, disbursement) {
	if (type == "checkbox") {
		disbursement.disbursementStatusTypeId =
			disbursement.disbursementStatusCheckbox === true ? 3 : 5;
	} else if (type == "dropdown") {
		disbursement.disbursementStatusCheckbox =
			disbursement.disbursementStatusTypeId == 3 ||
			disbursement.disbursementStatusTypeId == 4;
	}
}

const sorting = reactive({
	column: null,
	direction: "asc",
});

function sortData(column) {
	if (sorting.column === column) {
		sorting.direction = sorting.direction === "asc" ? "desc" : "asc";
	} else {
		sorting.column = column;
		sorting.direction = "asc";
	}
}

function getNestedValue(obj, path) {
	return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}

watch(pageSize, () => {
	triggerSearch(applications.search.text, currentPage.value, pageSize.value);
});

watch(selectedEnrollmentTerms, () => {
	triggerSearch(applications.search.text, 1);
});

watch(selectedDisbursementMethods, () => {
	triggerSearch(applications.search.text, 1);
});

watch(selectedDisbursementStatuses, () => {
	triggerSearch(applications.search.text, 1);
});

watch(
	() => route.query,
	() => {
		searchApplications();
	}
);

onMounted(async () => {
	await functions.getType("DisbursementStatusType");
	await functions.getType("DrawDisbursementStatusReasonType");
	await functions.getType("DisbursementCancellationReason");
	await functions.getSchoolType("DisbursementType");
	await functions.getEnrollmentTerms();
	await loadCachedDisbursementData();
	await searchApplications();
	loading.value = false;

	if (localStorage.getItem("cache.RecentlyViewedPages")) {
		applications.recentlyViewed = JSON.parse(
			localStorage.getItem("cache.RecentlyViewedPages")
		).filter((item) => item.page == pageName.toLowerCase());
	}
});
</script>

<style scoped>
.disbursement-section {
	margin-bottom: 15px;
}

.post-change-border {
	border: 1px dashed #d3d3d3;
	padding: 15px;
	border-radius: 5px;
}

.results-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;
	line-height: 44px;
	margin-right: 10px;
	font-family: Montserrat, arial, sans-serif;
	font-size: 19px;
	font-weight: 800;
	border-bottom: 1px solid rgb(183, 183, 183);
}

.results-info-pages {
	line-height: 20px;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 700;
	margin-left: 10px;
}

.xpage-search-filters {
	background-color: #f8f8f8;
	padding: 20px;
	width: 290px;
	border-radius: 10px;
	height: fit-content;
	margin-top: 5px;
}

.table-header {
	width: 200px;
	/* justify-content: space-between; */
}

.header-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.sorting-direction {
	margin-left: auto;
	padding-left: 10px;
}

.table-data {
	width: 85%;
	margin-left: 20px;
}

.clearButton {
	background-color: #f8f8f8;
	border: 1px solid #444444;
	border-radius: 5px;
	padding: 5px 10px;
	margin-top: 10px;
	font-weight: bold;
}

.clearButton:hover {
	background-color: #333333;
	color: white;
	font-weight: bold;
}

.page-size-selector select {
	padding: 5px 10px;
	font-size: 14px;
}

.page-size-selector {
	margin-left: 10px;
	display: inline-block;
	margin-right: 10px;
}

.xpage-searchbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
}

.pagination-controls {
	display: inline-block;
}

.pagination button:disabled {
	border-color: #eaeaea;
	cursor: not-allowed;
	color: #ccc;
}

.pagination .xclicked {
	background-color: #000;
	color: #fff;
}

.pagination span {
	padding: 5px 10px;
}

.pagination ellipsis {
	padding: 5px 10px;
}

.search-data {
	width: max-content;
}

.xpage-content-wrapper {
	display: flex;
}
</style>