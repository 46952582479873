<template>
	<div class="xheader">
		<div class="xheader-logo-mobile">
			<a href="#">
				<img src="@/assets/images/logo-student-choice-mobile.png" />
			</a>
		</div>

		<div
			class="xheader-menu-mobile"
			@click="vueStore.mobileMenu = !vueStore.mobileMenu">
			<div class="icon-menu"></div>
		</div>

		<div class="xheader-search" style="display: none">
			<input
				type="text"
				placeholder="Student Search..."
				v-model.trim="search.typing"
				@focus="search.focus = true"
				@blur="blurSearchbox"
				v-on:keyup="searchDropdown(search.typing)"
				v-on:keyup.enter="$event.target.blur()" />
			<div
				class="xheader-search-dropdown"
				v-if="search.typing.length >= 3 && search.focus">
				<nav v-if="computedApplicationsDropdown">
					<router-link
						v-for="(application, index) in computedApplicationsDropdown.slice(
							0,
							5
						)"
						:key="index"
						:to="`/${application.searchCategory.toLowerCase()}s/${
							application.referenceId
						}`"
						replace>
						<div class="xusericon" data-size="small">
							<div class="xusericon-picture">
								{{ application.initials || '' }}
							</div>
							<div class="xusericon-gender" data-gender=""></div>
						</div>
						<div class="xheader-search-dropdown-result">
							<h4>
								<span
									v-html="application.name || 'No Name'"
									style="vertical-align: middle"></span>
								<span
									class="xinfopill"
									:data-type="application.searchCategory"
									>{{ application.searchCategory }}</span
								>
							</h4>
							<h5
								v-if="application.searchNotification"
								v-html="application.searchNotification"></h5>
							<h5
								v-else
								v-html="application.lenderName || 'No Lender Provided'"></h5>
						</div>
					</router-link>
				</nav>
			</div>
			<div
				class="xheader-search-dropdown"
				v-if="search.typing.length < 3 && search.focus">
				<nav>
					<div
						v-if="recentlyViewed.length > 0 && search.typing.length < 3"
						style="
							text-transform: uppercase;
							font-size: 11px;
							margin-top: 20px;
							margin-bottom: 10px;
							padding-left: 15px;
						">
						Recently Viewed
					</div>
					<router-link
						v-for="(application, index) in recentlyViewed.slice(0, 5)"
						:key="index"
						:to="`/${application.page}/${application.id}`"
						replace>
						<div class="xusericon" data-size="small">
							<div class="xusericon-picture">
								{{ application.firstName?.charAt(0)
								}}{{ application.lastName?.charAt(0) }}
							</div>
							<div class="xusericon-gender" data-gender=""></div>
						</div>
						<div class="xheader-search-dropdown-result">
							<h4>
								<span
									v-html="application.name || 'No Name'"
									style="vertical-align: middle"></span>
								<span class="xinfopill" :data-type="application.category">{{
									application.category
								}}</span>
							</h4>
							<h5 v-html="functions.getLenderName(application.lenderId)"></h5>
						</div>
					</router-link>
					<div
						style="
							display: none;
							text-transform: uppercase;
							font-size: 11px;
							line-height: 36px;
							text-align: center;
							background: #eeeeee;
							cursor: pointer;
						"
						@click.prevent="headerSearch = ''">
						Show More
					</div>
				</nav>
			</div>
		</div>

		<div class="xheader-user" v-if="store.state.oidcStore.user">
			<a href="#" @click.prevent="toggle('headerDropdownVisble')">
				<div class="xusericon" data-size="small" style="float: left">
					<div class="xusericon-picture">
						{{ store.state.oidcStore.user.given_name?.charAt(0) || ''
						}}{{ store.state.oidcStore.user.family_name?.charAt(0) || '' }}
					</div>
				</div>
				<div class="xheader-user-name">
					{{ store.state.oidcStore.user.given_name }}
					{{ store.state.oidcStore.user.family_name }}
					<i class="icon-down-open-big"></i>
				</div>
				<div class="clear"></div>
			</a>
			<div
				class="xheader-user-dropdown"
				:class="{ visible: headerDropdownVisble }">
				<nav>
					<router-link to="/logout">Logout</router-link>
				</nav>
			</div>
		</div>
		<div class="clear"></div>
	</div>
</template>

<script setup>
	import store from '@/store';
	import { onMounted, reactive, inject, computed } from 'vue';
	import functions from '@/use/functions.js';

	var vueStore = inject('vueStore');
	var headerSearch = $ref('');
	var headerDropdownVisble = $ref(false);
	var recentlyViewed = $ref([]);
	var search = reactive({
		applicationData: [],
		loanData: [],
		typing: '',
		type: '',
		text: '',
		focus: false,
	});

	async function searchDropdown(searchText) {
		// clean the search text
		searchText = functions.cleanSearchText(searchText);
		search.typing = searchText;

		// search the applications
		functions
			.getApplications(searchText)
			.then((response) => {
				search.applicationData = response.data.data;
			})
			.catch((error) => {
				search.applicationData = [];
				console.log(error);
			});

		// search the loans
		functions
			.getLoans(searchText)
			.then((response) => {
				search.loanData = response.data.data;
			})
			.catch((error) => {
				search.loanData = [];
				console.log(error);
			});
	}

	function toggle(key) {
		// toggles the input variable true/false
		eval(`${key}.value=!${key}.value`);
	}

	function blurSearchbox() {
		setTimeout(() => {
			search.focus = false;
		}, 200);
	}

	const computedApplicationsDropdown = computed(() => {
		var data = [];

		if (Array.isArray(search.loanData)) {
			data = data.concat(search.loanData);
		}

		if (Array.isArray(search.applicationData)) {
			data = data.concat(search.applicationData);
		}

		return data;
	});

	onMounted(async () => {
		functions.getLenders();

		// if the localstorage has any recently viewed pages
		if (localStorage.getItem('cache.RecentlyViewedPages')) {
			// append to recentlyViewed
			recentlyViewed = JSON.parse(
				localStorage.getItem('cache.RecentlyViewedPages')
			);
		}
	});
</script>
