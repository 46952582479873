<template>
	<div
		class="xsidebar"
		:class="[vueStore.mobileMenu ? 'xsidebar-visible' : '']">
		<div class="xsidebar-head">
			<router-link to="/">
				<img src="@/assets/images/logo-student-choice.png" />
			</router-link>
			<h1 style="color: #00cd6f; font-size: 16px">School Portal</h1>
		</div>

		<nav class="xsidebar-nav">
			<div v-if="userType == 'Admin'">
				<h3>Menu</h3>
				<router-link
					to="/certifications"
					class="icon-doc-text"
					:class="{ 'router-link-active': $route.name == 'Loan' }"
					>Cert Requests</router-link
				>
				<router-link to="/disbursements" class="icon-money-1"
					>Disbursements</router-link
				>
				<router-link to="/reports" class="icon-doc-text" style="display: none"
					>Reports</router-link
				>
			</div>

			<div v-if="userType == 'Manager'">
				<router-link to="/dashboard" class="icon-home">Dashboard</router-link>
				<router-link
					to="/applications"
					class="icon-doc-text"
					:class="{ 'router-link-active': $route.name == 'Application' }"
					>Applications</router-link
				>
				<router-link to="/loans" class="icon-money" style="display: none"
					>Loans</router-link
				>

				<div style="display: none">
					<h3>Configuration</h3>
					<router-link to="/administration">Administration</router-link>
				</div>
			</div>

			<div v-if="userType == 'Concierge'">
				<router-link to="/dashboard" class="icon-home">Dashboard</router-link>
				<router-link
					to="/applications"
					class="icon-doc-text"
					:class="{ 'router-link-active': $route.name == 'Application' }"
					>Applications</router-link
				>
				<router-link to="/loans" class="icon-money" style="display: none"
					>Loans</router-link
				>
			</div>

			<div v-if="userType == 'Processor'">
				<router-link to="/dashboard" class="icon-home">Dashboard</router-link>
				<router-link to="/processing" class="icon-doc-text"
					>Processing</router-link
				>
			</div>
		</nav>
	</div>
</template>

<script setup>
	import { inject } from 'vue';

	var vueStore = inject('vueStore');
	var userType = $ref('Admin');
</script>

<style scoped></style>
