<template>
	<loader v-if="loading" />
	<page v-else>
		<div class="xpage-breadcrumbs">
			<router-link to="/dashboard" class="icon-home"></router-link>
			<span>></span>
			<router-link :to="`/${props.pageName.toLowerCase()}`">{{
				props.pageName
			}}</router-link>
		</div>

		<div class="xpage-inner">
			<h2>{{ props.pageName }}</h2>

			<div class="xpage-openapps-bar">
				<div class="xpage-openapps-bar-search">
					<div class="xsearchbox">
						<div class="xsearchbox-field">
							<input
								type="text"
								v-model.trim="applications.search.typing"
								@focus="applications.search.focus = true"
								@blur="blurSearchbox"
								v-on:keyup="searchDropdown(applications.search.typing)"
								v-on:keyup.enter="
									triggerSearch(applications.search.typing);
									$event.target.blur();
								"
								:placeholder="`Search for Certifications by Name, SSN, ID...`" />
							<i
								class="icon-cancel"
								v-if="applications.search.typing.length > 0"
								@click="
									clearSearchbox();
									triggerSearch(null, 1);
								"></i>
						</div>
						<div class="xsearchbox-submit">
							<button
								@click="triggerSearch(applications.search.typing, 1)"
								class="icon-search">
								<span>Search</span>
							</button>
						</div>
					</div>

					<div
						v-if="applications.search.error"
						style="display: inline-block; margin-left: 10px">
						Search must contain at least 3 characters.
					</div>

					<div
						class="xpage-openapps-bar-search-dropdown"
						v-if="
							applications.search.typing.length >= 3 &&
							applications.search.focus
						">
						<nav>
							<router-link
								v-for="(loan, index) in applications.dataDropdown"
								:key="index"
								:to="`/${props.pageName.toLowerCase()}/${loan.referenceId}`">
								<div class="xusericon" data-size="small">
									<div class="xusericon-picture">{{ loan.initials || '' }}</div>
									<div class="xusericon-gender" data-gender=""></div>
								</div>
								<div class="xheader-search-dropdown-result">
									<h4 v-html="loan.name || 'No Name'"></h4>
									<h5
										v-if="loan.searchNotification"
										v-html="loan.searchNotification"></h5>
									<h5
										v-else
										v-html="loan.lenderName || 'No Lender Provided'"></h5>
								</div>
							</router-link>
						</nav>
					</div>
					<div
						class="xpage-openapps-bar-search-dropdown"
						v-if="
							applications.search.typing.length < 3 && applications.search.focus
						">
						<nav v-if="applications.recentlyViewed">
							<div
								v-if="
									applications.recentlyViewed &&
									applications.search.typing.length < 3
								"
								style="
									text-transform: uppercase;
									font-size: 11px;
									margin-top: 20px;
									margin-bottom: 10px;
									padding-left: 15px;
								">
								Recently Viewed
							</div>
							<router-link
								v-for="(
									application, index
								) in applications.recentlyViewed.slice(0, 5)"
								:key="index"
								:to="`/${props.pageName.toLowerCase()}/${loan.id}`">
								<div class="xusericon" data-size="small">
									<div class="xusericon-picture">
										{{ loan.firstName?.charAt(0)
										}}{{ loan.lastName?.charAt(0) }}
									</div>
									<div class="xusericon-gender" data-gender=""></div>
								</div>
								<div class="xheader-search-dropdown-result">
									<h4 v-html="loan.name || 'No Name'"></h4>
									<h5 v-html="functions.getLenderName(loan.lenderId)"></h5>
								</div>
							</router-link>
							<div
								style="
									display: none;
									text-transform: uppercase;
									font-size: 11px;
									line-height: 36px;
									text-align: center;
									background: #eeeeee;
									cursor: pointer;
								"
								@click.prevent="headerSearch = ''">
								Show More
							</div>
						</nav>
					</div>
				</div>

				<div class="clear"></div>
			</div>

			<div class="xapplicationsearch">
				<div
					class="xapplicationsearch-left"
					@click.self="applications.filterDisplay = false"
					:class="{ visible: applications.filterDisplay }">
					<div class="xsidebar-filter">
						<div
							class="xsidebar-filters-close"
							@click="applications.filterDisplay = false">
							X
						</div>
						<h3>Filters</h3>
						<div class="xsidebar-filter-scroll">
							<div
								class="xsidebar-filter-section"
								v-for="(section, index) in applications.filters"
								:key="index">
								<h3>{{ section.description }}</h3>
								<div v-if="section.results.length > 7">
									<input
										type="text"
										v-model.trim="section.search"
										:placeholder="`Filter ${section.description}...`" />
								</div>
								<div
									v-for="(item, itemIndex) in section.results
										.filter(
											(item) =>
												!section.search ||
												item.displayName
													.toLowerCase()
													.includes(section.search.toLowerCase())
										)
										.slice(0, 7)"
									:key="itemIndex">
									<div v-if="section.type == 'radio'">
										<label class="xradio">
											{{ item.name }}
											<input
												type="radio"
												:name="'radio' + section.param"
												:checked="item.checked"
												v-on:input="selectFilter($event, item, section)" />
											<span></span>
										</label>
									</div>
									<div v-else>
										<label class="xcheck">
											{{ item.name }}
											<input
												type="checkbox"
												:checked="item.checked"
												v-on:input="selectFilter($event, item)" />
											<span></span>
										</label>
										<div v-if="item.children" style="padding-left: 20px">
											<label
												v-for="(child, childIndex) in item.children"
												:key="childIndex"
												class="xcheck">
												{{ child.name }}
												<input
													type="checkbox"
													:checked="child.checked"
													v-on:input="selectFilter($event, child, item)" />
												<span></span>
											</label>
										</div>
									</div>
								</div>
								<div
									v-if="section.results.length > 7"
									class="xseeall"
									@click="applications.modal = index">
									See all
								</div>
								<div class="xmodal-wrapper" v-if="applications.modal === index">
									<div class="xmodal-inner">
										<div class="xmodal">
											<div
												class="xmodal-close"
												@click="applications.modal = false">
												X
											</div>
											<h2>{{ section.description }}</h2>
											<input
												type="text"
												v-model.trim="section.search"
												:placeholder="`Filter ${section.description}...`" />
											<div
												style="
													text-align: left;
													max-height: 300px;
													overflow-y: auto;
												">
												<div
													v-for="(item, itemIndex) in section.results.filter(
														(item) =>
															!section.search ||
															item.displayName
																.toLowerCase()
																.includes(section.search.toLowerCase())
													)"
													:key="itemIndex">
													<label class="xcheck">
														{{ item.name }}
														<input
															type="checkbox"
															:checked="item.checked"
															v-on:input="selectFilter($event, item)" />
														<span></span>
													</label>
												</div>
											</div>
											<div class="xmodal-buttons">
												<button @click="applications.modal = false">
													Show {{ applications.query.totalCount }} Result<span
														v-if="applications.query.totalCount != 1"
														>s</span
													>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="xsidebar-filter-confirm"
						@click="applications.filterDisplay = false">
						Show {{ applications.query.totalCount }} Result<span
							v-if="applications.query.totalCount != 1"
							>s</span
						>
					</div>
				</div>

				<div class="xapplicationsearch-right">
					<div class="xapplicationsearch-right-title">
						<div v-if="typeof applications.data === 'string'">
							{{ applications.data }}
						</div>
						<div v-else>
							<div>
								Found {{ applications.query.totalCount }} Result<span
									v-if="applications.query.totalCount != 1"
									>s</span
								>
								<span
									v-if="applications.query.totalCount > 30"
									style="
										line-height: 20px;
										padding-bottom: 12px;
										text-transform: uppercase;
										font-size: 12px;
										font-weight: 700;
										margin-left: 10px;
									"
									>Page {{ applications.query.pageNumber }} of
									{{ Math.ceil(applications.query.totalCount / 30) }}</span
								>
							</div>
						</div>
					</div>

					<div>
						<div
							class="xfilter-button"
							v-if="applications.search.active"
							@click="
								clearSearchbox();
								triggerSearch(null, 1);
							">
							{{ 'Search: ' + applications.search.text }}
						</div>
						<div
							class="xfilter-button"
							v-for="(item, index) in selectedFilters()"
							@click="clearFilter(item)"
							:key="index">
							{{ item.name }}
						</div>
						<div
							class="xfilter-button"
							v-if="selectedFilters().length + applications.search.active > 1"
							@click="clearAllFiltersAndSearch()"
							data-type="clear">
							Clear All
						</div>
					</div>

					<div
						class="xpage-openapps-boxes"
						v-auto-animate="{ duration: 100, ...$animate }">
						<div
							class="b-table"
							style="margin-top: 5px"
							v-if="
								applications.data.length > 0 &&
								typeof applications.data !== 'string'
							"
							v-auto-animate="{ duration: 100, ...$animate }">
							<div class="table-wrapper has-mobile-cards">
								<table
									class="table is-fullwidth is-hoverable is-fullwidth xstudentloan">
									<thead>
										<tr>
											<th style="width: 110px">Status</th>
											<th>Student</th>
											<th style="width: 160px">Date of Birth</th>
											<th style="width: 160px">SSN</th>
											<th style="width: 120px">Amount</th>
										</tr>
									</thead>
									<template
										v-for="(loan, index) in applications.data"
										:key="index">
										<tr @click="showDetails(loan, index)">
											<td
												data-label="Status"
												style="
													background: rgb(255, 246, 224);
													color: #e9ac00;
													font-weight: 700;
												">
												Pending
											</td>
											<td data-label="Student">
												<div
													class="xusericon"
													data-size="small"
													style="float: left">
													<div class="xusericon-picture">
														{{ loan.initials }}
													</div>
													<div class="xusericon-gender" data-xgender=""></div>
												</div>
												<p style="margin: 0; font-weight: 700; font-size: 16px">
													{{
														`${loan.borrower.firstName} ${loan.borrower.lastName}`
													}}
												</p>
												<p
													style="
														margin: 0;
														margin-top: 2px;
														text-transform: uppercase;
														font-size: 11px;
														color: #adadad;
													">
													{{ loan.lenderName }}
												</p>
											</td>
											<td data-label="Date of Birth" style="font-weight: 700">
												{{
													loan.borrower.dateOfBirth
														? $moment(loan.borrower.dateOfBirth).format(
																'YYYY-MM-DD'
														  )
														: '—'
												}}
											</td>
											<td data-label="SSN" style="font-weight: 700">
												{{
													loan.borrower.ssn
														? `XXX-XX-${loan.borrower.ssn}`
														: '—'
												}}
											</td>
											<td data-label="Amount" style="font-weight: 700">
												{{
													loan.requestedAmount
														? `$${loan.requestedAmount?.toLocaleString('en-US')}`
														: '—'
												}}
											</td>
										</tr>

										<tr
											v-if="loan.showDetails && loan.disbursements"
											class="detail">
											<td colspan="7" style="background: #f4f5f9">
												<div>
													<comments type="loan" :id="loan.id"></comments>
												</div>
												<div class="xstudentloan-details">
													<div
														class="xtabletop"
														style="
															padding-bottom: 25px;
															margin-bottom: 25px;
															border-bottom: 1px solid #dddddd;
														">
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Address</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 500;
																">
																{{
																	[
																		loan.borrower.borrowerAddress
																			.addressLineOne,
																		loan.borrower.borrowerAddress
																			.addressLineTwo,
																		loan.borrower.borrowerAddress.city,
																		loan.borrower.borrowerAddress.addressState
																			.abbreviation,
																		loan.borrower.borrowerAddress.postalCode,
																	]
																		.filter((n) => n)
																		.join(', ') || 'Not Provided'
																}}
															</h2>
														</div>
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Email Address</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																">
																{{
																	loan.borrower.borrowerContact?.emailAddress ||
																	'—'
																}}
															</h2>
														</div>
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Phone</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																">
																{{
																	functions.phoneFormat(
																		loan.borrower.borrowerContact
																			?.phoneNumber || ''
																	)
																}}
															</h2>
														</div>
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Program Code</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																">
																—
															</h2>
														</div>
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Type</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																">
																—
															</h2>
														</div>
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">NCHER</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																">
																—
															</h2>
														</div>
													</div>

													<form
														@submit.prevent="confirmApplication()"
														autocomplete="off">
														<fieldset>
															<div>
																<div class="xfields">
																	<div class="xfield">
																		<label>Loan Start Date<i>*</i></label>
																		<input
																			type="date"
																			:min="currentDate"
																			:max="maxDate"
																			v-model="loan.loanPeriodStartDate"
																			placeholder="YYYY-MM-DD"
																			required />
																	</div>
																	<div class="xfield">
																		<label>Loan End Date<i>*</i></label>
																		<input
																			type="date"
																			:min="currentDate"
																			:max="maxDate"
																			v-model="loan.loanPeriodEndDate"
																			placeholder="YYYY-MM-DD"
																			required />
																	</div>
																	<div class="xfield">
																		<label>Enrollment Status<i>*</i></label>
																		<select v-model="loan.enrollmentStatus">
																			<option value="1">Full-Time</option>
																			<option value="2">Half-Time</option>
																			<option value="3">
																				Less Than Half-Time
																			</option>
																		</select>
																	</div>
																	<div class="xfield">
																		<label>Term / Grade Level<i>*</i></label>
																		<select v-model="loan.enrollmentTermId">
																			<option
																				v-for="(term, termIndex) in store.state
																					.types['EnrollmentTerms']"
																				:key="termIndex"
																				:value="term.id">
																				{{ term.description }}
																			</option>
																		</select>
																	</div>
																	<div class="xfield">
																		<label>CLUID<i>*</i></label>
																		<input type="text" v-model="loan.cluid" />
																	</div>
																	<div class="xfield">
																		<label
																			>Anticipated Graduation Date<i
																				>*</i
																			></label
																		>
																		<input
																			type="date"
																			:min="currentDate"
																			:max="maxDate"
																			v-model="loan.anticipatedGraduationDate"
																			placeholder="YYYY-MM-DD"
																			required />
																	</div>
																</div>
															</div>

															<div>
																<h3>
																	Disbursements
																	<button
																		v-if="
																			loan.disbursements.filter(
																				(d) =>
																					![2, 8, 9].includes(
																						d.disbursementTypeId
																					)
																			).length < 6
																		"
																		class="xregular"
																		@click.prevent="addDisbursement(index)"
																		style="
																			margin-left: 10px;
																			margin-bottom: 10px;
																		">
																		Add <i class="icon-down-open-big"></i>
																	</button>
																</h3>

																<div
																	class="b-table"
																	style="margin-top: 5px; max-width: 750px">
																	<div class="table-wrapper has-mobile-cards">
																		<table
																			class="table is-fullwidth is-hoverable is-fullwidth xstudentloan">
																			<thead>
																				<tr>
																					<th style="width: 50px"></th>
																					<th
																						style="
																							width: 60px;
																							text-align: center;
																						">
																						#
																					</th>
																					<th>Date</th>
																					<th style="width: 150px">Amount</th>
																				</tr>
																			</thead>
																			<template
																				v-for="(
																					disbursement, disbursementIndex
																				) in loan.disbursements.filter(
																					(d) =>
																						![2, 8, 9].includes(
																							d.disbursementTypeId
																						)
																				)"
																				:key="disbursementIndex">
																				<tr
																					v-if="
																						disbursement.deleted &&
																						disbursement.type != 'New'
																					">
																					<td colspan="4" class="xalertmessage">
																						Disbursement #{{
																							disbursementIndex + 1
																						}}
																						will be deleted once the record has
																						been saved. &nbsp;
																						<button
																							@click.prevent="
																								disbursement.deleted = false
																							">
																							Undo
																						</button>
																					</td>
																				</tr>
																				<tr v-else-if="!disbursement.deleted">
																					<td data-label="Delete" style="text-align: center">
																						<i v-if="[6, 7].includes(disbursement.disbursementTypeId)"
																						   class="icon-lock-1"
																						   style="font-size: 23px" />
																						<i v-else 
																						   class="icon-trash" 
																						   style=" font-size: 23px; color: red;" 
																						   @click="confirmDeleteDisbursement(loan, disbursement)" />
																					</td>
																					<td
																						data-label=""
																						style="text-align: center">
																						{{ disbursementIndex + 1 }}
																					</td>
																					<td
																						data-label="Disbursement Date"
																						style="font-weight: 700">
																						<input
																							type="date"
																							v-model="
																								disbursement.transactionDate
																							"
																							:min="currentDate"
																							:max="maxDate"
																							required
																							placeholder="YYYY-MM-DD"
																							:disabled="
																								[6, 7].includes(
																									disbursement.disbursementTypeId
																								)
																							"
																							style="
																								outline: 2px solid #dddddd;
																								border-radius: 4px;
																							"
																							:class="{
																								xwarning:
																									!disbursement.transactionDate ||
																									moment(
																										disbursement.transactionDate
																									).valueOf() <
																										moment(
																											currentDate
																										).valueOf() ||
																									moment(
																										disbursement.transactionDate
																									).valueOf() >
																										moment(maxDate).valueOf(),
																							}" />
																					</td>
																					<td data-label="Amount">
																						<i class="dollar">$</i>
																						<input
																							type="text"
																							v-model="
																								disbursement.certifiedAmount
																							"
																							v-on:keyup="calculateTotal(loan)"
																							:class="{
																								xwarning:
																									!disbursement.certifiedAmount ||
																									((disbursement.certifiedAmount <
																										50 ||
																										disbursement.certifiedAmount >
																											99999 ||
																										loan.disbursementTotal >
																											loan.requestedAmount) &&
																										![6, 7].includes(
																											disbursement.disbursementTypeId
																										)),
																							}"
																							:disabled="
																								[2, 6, 7, 8, 9].includes(
																									disbursement.disbursementTypeId
																								)
																							"
																							style="
																								padding-left: 20px;
																								outline: 2px solid #dddddd;
																								border-radius: 4px;
																							" />
																					</td>
																				</tr>
																			</template>
																			<template
																				v-if="loan.disbursements?.length > 0">
																				<tr class="footer">
																					<td
																						colspan="3"
																						style="text-align: right">
																						Requested by Student:&nbsp;
																					</td>
																					<td>
																						{{
																							loan.requestedAmount
																								? `$${loan.requestedAmount?.toLocaleString(
																										'en-US'
																								  )}`
																								: '—'
																						}}
																					</td>
																				</tr>
																				<tr class="footer">
																					<td
																						colspan="3"
																						style="text-align: right"
																						:class="{
																							xwarning:
																								loan.disbursementTotal >
																								loan.requestedAmount,
																						}">
																						Certified Total:&nbsp;
																					</td>
																					<td
																						:class="{
																							xwarning:
																								loan.disbursementTotal >
																								loan.requestedAmount,
																						}">
																						{{
																							loan.disbursementTotal
																								? `$${loan.disbursementTotal?.toLocaleString(
																										'en-US'
																								  )}`
																								: '—'
																						}}
																					</td>
																				</tr>
																			</template>
																			<template v-else>
																				<tr>
																					<td
																						colspan="4"
																						style="text-align: center">
																						You must add at least one
																						disbursement
																					</td>
																				</tr>
																			</template>
																		</table>
																	</div>
																</div>
															</div>

															<div style="max-width: 750px; display: none">
																<div
																	v-if="loan.disbursementTotal > loan.requestedAmount"
																	class="xwarningbox-small">
																	You have exceeded the requested amount by ${{
																		(
																			loan.disbursementTotal - loan.requestedAmount
																		).toLocaleString('en-US')
																	}}
																</div>
															</div>

															<div>
																<div v-if="loan.disbursements.length > 0">
																	<button
																		class="xedit icon-ok"
																		@click.prevent="
																			certifyLoan(loan, 'confirmation')
																		"
																		style="
																			margin: auto;
																			margin-top: 20px;
																			margin-right: 10px;
																			float: none;
																			background: #00cd6f;
																			border: 5px solid #00cd6f;
																		">
																		&nbsp;&nbsp;Certify Loan
																	</button>
																	<button
																		class="xedit icon-cancel"
																		@click.prevent="
																			cancelLoan(loan, 'confirmation')
																		"
																		style="
																			margin: auto;
																			margin-top: 20px;
																			margin-right: 10px;
																			float: none;
																			background: #d50000;
																			border: 5px solid #d50000;
																		">
																		&nbsp;&nbsp;Terminate Loan
																	</button>
																	<button
																		class="xedit"
																		@click.prevent="
																			saveChangesLoan(loan, 'confirmation')
																		"
																		style="
																			margin: auto;
																			margin-top: 20px;
																			margin-right: 10px;
																			float: none;
																			background: #000000;
																			border: 5px solid #000000;
																		">
																		Save Changes
																	</button>
																</div>
															</div>
														</fieldset>
													</form>
												</div>
											</td>
										</tr>
									</template>
								</table>
							</div>
						</div>
						<div v-if="applications.data.length === 0" class="xfoundnothing">
							<h2 style="padding-top: 5px; margin-bottom: 10px">
								No matches found
							</h2>
							<b>0</b> results
							<span v-if="applications.search.active"
								>for <b>"{{ applications.search.text }}"</b></span
							>
							<span v-if="selectedFilters('text').length > 0"
								>with the filters</span
							>
							<b
								><span>{{ selectedFilters('text') }}</span></b
							>
						</div>

						<div
							v-if="
								applications.query.totalCount > 30 &&
								typeof applications.data !== 'string'
							"
							class="xpagnation">
							<button
								v-for="number in Math.ceil(applications.query.totalCount / 30)"
								:key="number"
								:class="{
									xselected:
										number === applications.query.pageNumber ||
										(number === 1 && !applications.query.pageNumber),
								}"
								@click="triggerSearch(applications.search.typing, number)">
								{{ number }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<script setup>
	import { reactive, onMounted, computed, watch, defineProps } from 'vue';
	import page from '@/views/layout/Page.vue';
	import loader from '@/components/Loader.vue';
	import store from '@/store';
	import { useRouter, useRoute } from 'vue-router';
	import functions from '@/use/functions.js';
	import axios from 'axios';
	import moment, { utc } from 'moment-timezone';
	import comments from '@/components/Comments.vue';

	//////////////
	// vue data //
	//////////////

	var props = defineProps(['filters', 'pageName']);
	var currentDate = new Date().toISOString().split('T')[0];
	var maxDate = moment(currentDate).add(10, 'y').format('YYYY-MM-DD');
	var loading = $ref(true);
	var route = useRoute();
	var router = useRouter();
	var applications = reactive({
		data: [],
		dataDropdown: [],
		dropdownData: false,
		filterDisplay: false,
		filterOrder: 0,
		filters: props.filters,
		layout: 'boxes',
		modal: false,
		recentlyViewed: false,
		search: {
			text: '',
			type: '',
			typing: '',
			timestamp: 0,
			active: false,
			focus: false,
			error: false,
			dropdown: false,
		},
		query: {
			pageNumber: false,
			totalCount: 0,
		},
	});

	/////////////////
	// vue methods //
	/////////////////

	async function getDisbursements(drawId, drawIndex) {
		///////////////////////////////////////////////////////
		// description: fetches all disbursements for a draw //
		///////////////////////////////////////////////////////

		await axios(`/api/loanservice/draws/${drawId}/disbursements`, {
			require: 'json',
			method: 'GET',
		})
			.then((response) => {
				response.data.disbursements.forEach((disbursement) => {
					disbursement.transactionDate =
						disbursement.transactionDate?.split('T')?.[0] || '';
				});

				applications.data[drawIndex].disbursements =
					response.data.disbursements;

				calculateTotal(applications.data[drawIndex]);
			})
			.catch((error) => {
				console.log(
					`API Error: /api/loanservice/draws/${drawId}/disbursements`,
					error
				);
			});
	}

	async function updateDraw(loan, updateType) {
		/////////////////////////////////////////////////
		// description: updates a draw in the database //
		/////////////////////////////////////////////////

		var data = JSON.parse(JSON.stringify(loan));

		// remove all dashes from the loan dates because the database requires yyyymmdd
		data.loanPeriodStartDate = data.loanPeriodStartDate.replace(/-/g, '');
		data.loanPeriodEndDate = data.loanPeriodEndDate.replace(/-/g, '');

		if (updateType == 'Certify') {
			// sets the draw to certified
			data.drawStatusTypeId = 3;
		}

		return await axios(`/api/loanservice/draws/update`, {
			require: 'json',
			method: 'POST',
			data: data,
		})
			.then((response) => {
				return true;
			})
			.catch((error) => {
				console.log(`API Error: /api/loanservice/draws/update`, error);
				return false;
			});
	}

	async function getEnrollmentTerms() {
		var localData = functions.checkLocalStorage(`cache.EnrollmentTerms`);

		// if the type is stored in localstorage
		if (localData) {
			store.state.types['EnrollmentTerms'] = localData.data;
		} else {
			await axios(`/api/loanservice/EnrollmentTerms`, { require: 'json' })
				.then((response) => {
					// add the data to localstorage
					localStorage.setItem(
						`cache.EnrollmentTerms`,
						JSON.stringify({ timestamp: Date.now(), data: response.data })
					);
					store.state.types['EnrollmentTerms'] = response.data;
				})
				.catch((error) => {
					console.log(`API Error: /api/loanservice/EnrollmentTerms`, error);
					return false;
				});
		}
	}

	function showDetails(loan, index) {
		///////////////////////////////////////////////////////////
		// description: expands loan details to show loan fields //
		///////////////////////////////////////////////////////////

		loan.showDetails = !loan.showDetails;

		if (loan.showDetails) {
			getDisbursements(loan.id, index);
		}
	}

	function calculateTotal(loan) {
		////////////////////////////////////////////////////////////////////////////
		// description: calculates the total amount of dispursements in the table //
		////////////////////////////////////////////////////////////////////////////

		var disbursementTotal = 0;

		// for each of the disbursements where the certified amount is not null, is not deleted, and is not cancelled
		loan.disbursements
			.filter(
				(d) =>
					d.certifiedAmount &&
					!d.deleted &&
					![2, 8, 9].includes(d.disbursementTypeId)
			)
			.forEach((disbursement) => {
				// increment the disbursement total
				disbursementTotal += Number(disbursement.certifiedAmount);
			});

		// set the disbursement total on the loan
		loan.disbursementTotal = disbursementTotal;
	}

	function addDisbursement(loanIndex) {
		///////////////////////////////////////////////////
		// description: adds a dispursement to the table //
		///////////////////////////////////////////////////

		applications.data[loanIndex].disbursements.push({
			type: 'New',
		});
    }

    async function createDisbursements(draw) {
        // filter new disbursements
        let newDisbursements = draw.disbursements.filter(disbursement => disbursement.type == 'New');

        try {
            // update existing disbursements
            draw.disbursements.filter(disbursement => disbursement.type != 'New')
                .forEach(async disbursement => {
                    await updateDisbursement(disbursement);
                });

            await axios.post(`/api/loanservice/draw/${draw.id}/disbursements`, newDisbursements);

            return true;
        } catch (error) {
            console.log(`API Error: /api/loanservice/draw/${draw.id}/disbursements`, error);
            return false;
        }
    }

	async function updateDisbursement(disbursement) {
		/////////////////////////////////////////////////////////
		// description: updates a dispursement in the database //
		/////////////////////////////////////////////////////////

		// make a clone of the disbursement
		var disbursementClone = JSON.parse(JSON.stringify(disbursement));

		// format the transaction date to utc, offset by the user's timezone
		disbursementClone.transactionDate = moment
			.utc(
				moment(disbursementClone.transactionDate)
					.tz(moment.tz.guess())
					.valueOf()
			)
			.format();

		return await axios(`/api/loanservice/disbursements/update`, {
			require: 'json',
			method: 'POST',
			data: disbursementClone,
		})
			.then((response) => {
				return true;
			})
			.catch((error) => {
				console.log(`API Error: /api/loanservice/disbursements/update`, error);
				return false;
			});
    }

    function confirmDeleteDisbursement(draw, disbursement) {
        functions.openModal({
            title: 'Confirm',
            description: 'Are you sure you want to delete this?',
            buttons: [
                {
                    title: 'Cancel',
                    onClick: () => functions.closeModal()
                },
                {
                    title: 'Delete',
                    onClick: () => deleteDisbursement(draw, disbursement)
                },
            ],
        });
    }

    function deleteDisbursement(draw, disbursement) {
        functions.closeModal();

        const disbursementIndex = draw.disbursements.indexOf(disbursement);

        if (disbursementIndex != -1) {
            draw.disbursements.splice(disbursementIndex, 1);

            calculateTotal(draw);
        }
    }

	async function certifyLoan(loan, confirmation) {
		/////////////////////////////////////////////////////////////////////////////
		// description: checks all loan fields for errors, then certifies the loan //
		/////////////////////////////////////////////////////////////////////////////

		if (confirmation) {
			var errors = [];

			loan.disbursements
				.filter(
					(d) =>
						(d.disbursementTypeId == 1 || !d.disbursementTypeId) && !d.deleted
				)
				.forEach((disbursement) => {
					if (
						!disbursement.certifiedAmount ||
						disbursement.certifiedAmount < 50
					) {
						disbursement.warning = true;
						errors.push('• The Certified Total must be at least $50');
					} else {
						disbursement.warning = false;
					}

					if (
						moment(disbursement.transactionDate).valueOf() <
							moment(currentDate).valueOf() ||
						moment(disbursement.transactionDate).valueOf() >
							moment(maxDate).valueOf()
					) {
						disbursement.dateWarning = true;
						errors.push('• The Disbursement Dates must be future dates');
					} else {
						disbursement.dateWarning = false;
					}
				});

			if (loan.disbursementTotal > loan.requestedAmount) {
				errors.push(
					'• The Certified Total must be less than or equal to the Requested Amount'
				);
			}

			// if there are no errors
			if (errors.length === 0) {
				functions.openModal({
					title: 'Confirm',
					description: 'Are you sure you want to certify this loan?',
					buttons: [
						{ title: 'Cancel', onClick: () => functions.closeModal() },
						{ title: 'Certify', onClick: () => certifyLoan(loan) },
					],
				});

				return;
			} else {
				// remove all duplicate errors from the array
				errors = Array.from(new Set(errors));

				functions.openModal({
					title: 'Alert',
					description: 'Please resolve the following issues before certifying:',
					html: `<p>${errors.join('<br/>')}</p>`,
				});
			}
		} else {
			functions.closeModal();

			var fails = 0;

			if (!await createDisbursements(loan)) {
				fails++;
			}

			if (!(await updateDraw(loan, 'Certify'))) {
				fails++;
			}

			// if there are any errors
			if (fails > 0) {
				functions.openModal({
					title: 'Error',
					description:
						'There was an error certifying this record. Please try again.',
				});
			} else {
				functions.openModal({
					title: 'Success',
					description: 'Loan has been successfully certified.',
					buttons: [
						{
							title: 'Close',
							onClick: () => {
								functions.closeModal();
								loan.showDetails = false;
							},
						},
					],
				});
			}
		}
	}

	async function saveChangesLoan(loan, confirmation) {
		/////////////////////////////////////////////////////////////
		// description: saves changes to a loan without certifying //
		/////////////////////////////////////////////////////////////

		if (confirmation) {
			functions.openModal({
				title: 'Confirm',
				description: 'Are you sure you want to save changes to this loan?',
				buttons: [
					{ title: 'Cancel', onClick: () => functions.closeModal() },
					{ title: 'Save Changes', onClick: () => saveChangesLoan(loan) },
				],
			});
		} else {
			functions.closeModal();

			var fails = 0;


            if (!await createDisbursements(loan)) {
                fails++;
            }

			if (!(await updateDraw(loan, null))) {
				fails++;
			}

			// if there are any errors
			if (fails > 0) {
				functions.openModal({
					title: 'Error',
					description:
						'There was an error saving this record. Please try again.',
				});
			} else {
				functions.openModal({
					title: 'Success',
					description: 'Loan has been successfully saved.',
					buttons: [
						{
							title: 'Close',
							onClick: () => {
								functions.closeModal();
								loan.showDetails = false;
							},
						},
					],
				});
			}
		}
	}

	async function cancelLoan(loan, confirmation) {
		/////////////////////////////////
		// description: cancels a loan //
		/////////////////////////////////

		if (confirmation) {
			functions.openModal({
				title: 'Confirm',
				description: 'Are you sure you want to cancel this loan?',
				buttons: [
					{ title: 'No', onClick: () => functions.closeModal() },
					{ title: 'Yes', onClick: () => cancelLoan(loan) },
				],
			});
		} else {
			functions.closeModal();

			await axios(`/api/loanservice/draws/update`, {
				require: 'json',
				method: 'POST',
				data: {
					id: loan.id,
					drawStatusTypeId: 4,
				},
			})
				.then(() => {
					functions.openModal({
						title: 'Success',
						description: 'The loan has been cancelled!',
						buttons: [
							{
								title: 'Close',
								onClick: () => {
									functions.closeModal();
									loan.showDetails = false;
								},
							},
						],
					});
				})
				.catch((error) => {
					functions.openModal({
						title: 'Error',
						description:
							'There was an issue canceling this loan. Please try again.',
					});

					console.log(`API Error: /api/loanservice/draws/update`, error);
				});
		}
	}

	function discardChanges(loanIndex, confirmation) {
		////////////////////////////////////////////////////
		// description: discards all pending loan changes //
		////////////////////////////////////////////////////

		if (confirmation) {
			functions.openModal({
				title: 'Confirm',
				description: 'Are you sure you want to discard changes?',
				buttons: [
					{ title: 'Cancel', onClick: () => functions.closeModal() },
					{
						title: 'Discard Changes',
						onClick: () => discardChanges(loanIndex),
					},
				],
			});
		} else {
			functions.closeModal();

			applications.data[loanIndex].showDetails = false;
		}
	}

	async function triggerSearch(searchText, pageNumber) {
		//////////////////////////////////////////////////////////////////////////////////////////
		// description: adds filters to the page url parameters to trigger searchApplications() //
		//////////////////////////////////////////////////////////////////////////////////////////

		if (searchText) {
			searchText = functions.cleanSearchText(searchText);
			applications.search.typing = searchText;
			applications.search.text = searchText;
			applications.search.active = true;
		} else if (applications.search.active && applications.search.text) {
			searchText = functions.cleanSearchText(applications.search.text);
			applications.search.typing = searchText;
			applications.search.text = searchText;
		}

		if (pageNumber) {
			applications.query.pageNumber = pageNumber;
		}

		// if the user is searching by ssn
		if (functions.isValidSSN(searchText)) {
			searchApplications(searchText);
		} else {
			var filters = JSON.parse(JSON.stringify(applications.filters));

			await functions
				.getSearchParameters(searchText, filters, applications.query.pageNumber)
				.then((response) => {
					// convert the object to url parameters
					var urlParameters = functions.objectToUrlString(response.parameters);

					// redirect to the search url to perform the search
					router.push('?' + urlParameters);
				})
				.catch((error) => {
					functions.openModal('Error Getting Search Parameters');
					console.log('Error: triggerSearch()->getSearchParameters()', error);
				});
		}
	}

	async function searchApplications() {
		//////////////////////////////////////////////////////////////////////////////////////
		// description: searches applications or loans and displays the results on the page //
		//////////////////////////////////////////////////////////////////////////////////////

		applications.data = 'Loading...';
		applications.search.timestamp = Date.now();

		var response = await functions.getDraws(
			applications.search.text,
			applications.filters,
			applications.query.pageNumber
		);

		if (response) {
			if (response.config.postTime >= applications.search.timestamp) {
				// calculate how long the data took to load
				var responseTime = Date.now() - response.config.postTime;

				// make sure the response takes at least 200ms so the page doesn't change too quickly for the animation
				setTimeout(() => {
					applications.data = response.data.items;

					// for each of the loans
					applications.data.forEach((loan) => {
						loan.totalValue = 0;
					});

					applications.query = {
						pageNumber: response.data.pageNumber,
						totalCount: response.data.totalCount,
					};
				}, 200 - responseTime);
			}
		} else {
			applications.data = 'Error Loading Results';
		}
	}

	async function searchDropdown(searchText) {
		///////////////////////////////////////////////////////////////////////////////////////////////
		// description: displays search results in a dropdown as the user is typing in the searchbox //
		///////////////////////////////////////////////////////////////////////////////////////////////

		// clean the search text
		searchText = functions.cleanSearchText(searchText);
		applications.search.typing = searchText;
		applications.search.timestamp = Date.now();

		await functions
			.getDraws(searchText)
			.then((response) => {
				if (
					response &&
					response.config.postTime >= applications.search.timestamp
				) {
					applications.dataDropdown = response.data.data;
				}
			})
			.catch((error) => {
				applications.dataDropdown = false;
				console.log(error);
			});
	}

	async function getParametersFromUrl() {
		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// description: checks which parameters are present in the page url and selects filters based on those parameters //
		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		// clear all existing filters on the page
		clearAllFilters();

		if (props.pageName == 'Applications' || 1 == 1) {
			// check the `all` radio button in the filters
			applications.filters
				.find((f) => f.param == 'ProductIds')
				.results.find((f) => f.name == 'All').checked = true;
		}

		// if the current page url has parameters
		if (Object.keys(route.query).length > 0) {
			// for each of the url parameters
			Object.entries(route.query).forEach(([key, value]) => {
				// if the parameter value is an array when parsed
				if (Array.isArray(JSON.parse(value))) {
					var values = JSON.parse(value);

					if (key == 'SearchTerms') {
						applications.search.active = true;
						applications.search.text = values.join(' ');
						applications.search.typing = values.join(' ');
					} else {
						// for each of the values in the array
						values.forEach((value) => {
							var selectedFilter = applications.filters.find(
								(f) => f.param == key
							);

							if (selectedFilter) {
								selectedFilter.results.forEach((result) => {
									if (selectedFilter.type == 'radio') {
										// for each of the radio buttons
										selectedFilter.results.forEach((item) => {
											// uncheck all the radio buttons
											item.checked = false;
										});

										// set checked to true
										selectedFilter.results.filter(
											(f) => f.id == value
										)[0].checked = true;

										// if the result has children
									} else if (result.children) {
										result.children.forEach((child) => {
											if (Number(child.id) === Number(value)) {
												// set the child checked to true
												child.checked = true;
											}
										});

										// set the result to "checked" if every child is checked (the .every() method returns true or false)
										result.checked = result.children.every(
											(child) => child.checked
										);
									} else if (Number(result.id) === Number(value)) {
										result.checked = true;
									}
								});
							}
						});
					}

					// if the parameter value is a string when parsed
				} else {
					if (key == 'SearchTerm') {
						applications.search.active = true;
						applications.search.text = value;
						applications.search.typing = value;
					} else if (key == 'PageNumber') {
						applications.query.pageNumber = Number(value);
					}
				}
			});
		}
	}

	async function setLenderFilters() {
		//////////////////////////////////////////////////////////////////////////////////
		// description: gets the lenders and sets them as filter options on the sidebar //
		//////////////////////////////////////////////////////////////////////////////////

		// if the user has admin access to see all lenders
		if (store.state.oidcStore.user.los_access == 'Yes') {
			// check if the lenders are not already loaded
			if (!applications.filters.find((filter) => filter.param == 'LenderIds')) {
				// get the lenders
				await functions
					.getLenders()
					.then(() => {
						var lenderData = JSON.parse(
							JSON.stringify(store.state.types['Lenders'])
						);

						lenderData.forEach((item) => {
							// add a checked field
							item.checked = false;
						});

						// sort the lenders by name
						lenderData.sort((a, b) => a.name.localeCompare(b.name));

						// insert the data into applications.filters
						applications.filters.push({
							description: 'Credit Union',
							param: 'LenderIds',
							results: lenderData,
						});
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
	}

	function selectFilter(event, input, parent) {
		////////////////////////////////////////////////////
		// description: executes when a filter is clicked //
		////////////////////////////////////////////////////

		if (parent?.type == 'radio') {
			// for each of the radio buttons
			parent.results.forEach((item) => {
				// uncheck all the radio buttons
				item.checked = false;
			});

			// check the radio button which was just clicked
			input.checked = true;
		} else {
			// update the value on the clicked checkbox
			input.checked = event.target.checked;

			input.filterOrder = applications.filterOrder++;

			// if the filter has children
			if (input.children) {
				// loop through each of the children of the parent
				input.children.forEach((item) => {
					item.filterOrder = applications.filterOrder++;

					// set the child to match the parent
					item.checked = event.target.checked;
				});
			}

			// if the filter has a parent
			if (parent) {
				// set the parent to "checked" if every child is checked (the .every() method returns true or false)
				parent.checked = parent.children.every((child) => child.checked);
			}
		}

		triggerSearch(applications.search.text, 1);
	}

	function clearSearchbox() {
		///////////////////////////////////////////////////////////////
		// description: clears the search box and deactivates search //
		///////////////////////////////////////////////////////////////

		// reset the search text
		applications.search.text = '';
		applications.search.typing = '';

		// if the search is active (and not just a dropdown)
		if (applications.search.active) {
			// deactivate the search
			applications.search.active = false;
		}
	}

	function clearFilter(item) {
		////////////////////////////////////////////////////////
		// description: clears a single filter on the sidebar //
		////////////////////////////////////////////////////////

		// uncheck the item
		item.checked = false;

		// if the item has children
		if (item.children) {
			// for each of the children
			item.children.forEach((child) => {
				// uncheck all the child fields
				child.checked = false;
			});
		}

		triggerSearch(applications.search.text, 1);
	}

	function clearAllFiltersAndSearch() {
		// this redirects to the current url without any query parameters in the url
		router.push('');
	}

	function clearAllFilters() {
		////////////////////////////////////////////////////////
		// description: clears all the filters on the sidebar //
		////////////////////////////////////////////////////////

		// for each filter section
		applications.filters.forEach((section) => {
			// for each of the fields inside that filter section
			section.results.forEach((field) => {
				// uncheck all the fields
				field.checked = false;

				// for each of the children
				field.children?.forEach((child) => {
					// uncheck the child fields
					child.checked = false;
				});
			});
		});

		clearSearchbox();
	}

	function selectedFilters(output) {
		///////////////////////////////////////////////////////
		// description: returns an array of selected filters //
		///////////////////////////////////////////////////////

		var data = [];

		// for each filter section that is not a radio button
		applications.filters
			.filter((section) => section.type != 'radio')
			.forEach((section) => {
				// for each of the fields inside that filter section
				section.results.forEach((field) => {
					// if the field has children and is not checked
					if (field.children && !field.checked) {
						// for each of the children
						field.children.forEach((child) => {
							// if the child is checked
							if (child.checked) {
								// add the section to the object
								child['section'] = section.description;

								// push the child to the data array
								data.push(child);
							}
						});

						// else if the field is checked
					} else if (field.checked) {
						// add the section to the object
						field['section'] = section.description;

						// push the field to the data array
						data.push(field);
					}

					if (field.children) {
						// set the field to "checked" if every child is checked (the .every() method returns true or false)
						field.checked = field.children.every((child) => child.checked);
					}
				});
			});

		// sort the filter buttons by the order they were clicked
		data.sort((a, b) => {
			return a.filterOrder - b.filterOrder;
		});

		// option to output the array as text
		if (output == 'text') {
			// convert the array to a string to return selected filters as text
			data = data
				.map((field) => {
					return `"${field.name}"`;
				})
				.join(' or ');
		}

		return data;
	}

	function blurSearchbox() {
		// waits 0.2 seconds before closing the searchbox so the dropdown click works without issues
		setTimeout(() => {
			applications.search.focus = false;
		}, 200);
	}

	watch(
		() => route.params,
		async () => {
			///////////////////////////////////////////////////////////////////
			// vue watch - executes each time the page url parameters change //
			///////////////////////////////////////////////////////////////////

			await getParametersFromUrl();
			searchApplications();
		}
	);

	onMounted(async () => {
		//////////////////////////////////////////////////////////////////////
		// vue onMounted - executes each time the page is finished mounting //
		//////////////////////////////////////////////////////////////////////

		getEnrollmentTerms();
		await setLenderFilters();
		await getParametersFromUrl();
		searchApplications();

		loading = false;

		// if the localstore has any recently viewed applications
		if (localStorage.getItem('cache.RecentlyViewedPages')) {
			// retreive the data from localstorage
			applications.recentlyViewed = JSON.parse(
				localStorage.getItem('cache.RecentlyViewedPages')
			).filter((item) => item.page == props.pageName.toLowerCase());
		}
	});
</script>
