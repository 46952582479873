<template>
	<Loader :stretch="true" :inverse="true"></Loader>
</template>

<script>
	import { defineComponent } from 'vue';
	import Loader from '../components/Loader.vue';

	export default defineComponent({
		components: {
			Loader,
		},
		mounted() {
			this.$router.push({ path: '/certifications' });
		},
	});
</script>
