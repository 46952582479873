<template>
	<Loader v-if="loading" />
	<div>
		<Page>
		<div class="xpage-breadcrumbs">
			<router-link to="/dashboard" class="icon-home"></router-link>
			<span>></span>
			<router-link to="/certifications">{{ pageName }}</router-link>
		</div>
		<div class="xpage-inner">
				<h2>{{ pageName }}</h2>
			<!-- Search Bar -->
			<div class="xpage-searchbar">
				<div class="xsearchbox">
					<div class="xsearchbox-field">
						<input
							type="text"
							v-model.trim="applications.search.typing"
							@focus="applications.search.focus = true"
							@blur="blurSearchbox"
							@keyup="searchDropdown(applications.search.typing)"
							@keyup.enter="
								triggerSearch(applications.search.typing);
								$event.target.blur();
							"
							:placeholder="`Search by First Name, LastName, CLUID, SSN...`"
						/>
						<i
							class="icon-cancel"
							v-if="applications.search.typing.length > 0"
							@click="
								clearSearchbox();
								triggerSearch(null, 1);
							"
						></i>
					</div>
					<div class="xsearchbox-submit">
						<button
							@click="triggerSearch(applications.search.typing, 1)"
							class="icon-search"
						>
							<span>Search</span>
						</button>
					</div>
				</div>
				<div class="page-size-selector">
					<select v-model="pageSize" @change="changePageSize">
						<option value="" disabled></option>
						<option value="30">30</option>
						<option value="60">60</option>
						<option value="90">90</option>
					</select>
				</div>
			</div>
			<!-- Filters and Table Data -->
			<div class="xpage-content-wrapper">
				<!-- Filters -->
				<div class="filters xpage-search-filters">
					<div class="xpage-search-filter-header">
						<div class="xpage-search-filter-title">
							<h3>Search Filters</h3>
						</div>
					</div>
					<div class="xpage-search-filter-content">
						<!-- Enrollment Term Filter -->
						<div class="xpage-search-filter-section">
							<h3>Enrollment Term</h3>
							<div class="xpage-search-filter-section-content">
								<label
									v-for="term in enrollmentTerms"
									:key="term.id"
									class="xcheck"
								>
									{{ term.description }}
									<input
										type="checkbox"
										:value="term.id"
										v-model="selectedEnrollmentTerms"
									/>
									<span></span>
								</label>
							</div>
							<div style="display: flex; justify-content: center">
								<button
									class="clearButton"
									@click="clearSelection('enrollmentTerms')"
								>
									Clear & Reset
								</button>
							</div>
						</div>
						<!-- Certification Method Filter -->
						<div class="xpage-search-filter-section">
							<h3>Certification Method</h3>
							<div class="xpage-search-filter-section-content">
								<label
									v-for="method in certificationMethods"
									:key="method.id"
									class="xcheck"
								>
									{{ method.name }}
									<input
										type="checkbox"
										:value="method.id"
										v-model="selectedCertificationMethods"
									/>
									<span></span>
								</label>
							</div>
							<div style="display: flex; justify-content: center">
								<button
									class="clearButton"
									@click="clearSelection('certificationMethods')"
								>
									Clear & Reset
								</button>
							</div>
						</div>
						<!-- Draw Status Filter -->
						<div class="xpage-search-filter-section">
							<h3>Draw Status</h3>
							<div class="xpage-search-filter-section-content">
								<label
									v-for="status in drawStatuses"
									:key="status.id"
									class="xcheck"
								>
									{{ status.name }}
									<input
										type="checkbox"
										:value="status.id"
										v-model="selectedDrawStatuses"
									/>
									<span></span>
								</label>
							</div>
							<div style="display: flex; justify-content: center">
								<button
									class="clearButton"
									@click="clearSelection('drawStatuses')"
								>
									Clear & Reset
								</button>
							</div>
						</div>
					</div>
				</div>
				<!-- Table Data -->
				<div class="xpage-search-results table-data">
					<div>
						<div v-if="typeof filteredAndSortedApplications === 'string'">
							{{ filteredAndSortedApplications }}
						</div>
						<div
							v-if="filteredAndSortedApplications.length > 0"
							class="results-info"
						>
							Found {{ filteredAndSortedApplications.length }} results
							<span class="results-info-pages">
								Page {{ currentPage }} of {{ totalPageCountText }}
							</span>
							<!-- Pagination -->
							<div v-if="totalPages > 1" class="pagination">
								<div class="pagination-controls">
									<button
										@click="changePage(currentPage - 1)"
										:disabled="currentPage <= 1"
									>
										&lt;
									</button>
									<button
										v-for="n in totalPages"
										:key="n"
										:class="{ xclicked: n === currentPage }"
										@click="changePage(n)"
									>
										{{ n }}
									</button>
									<button
										@click="changePage(currentPage + 1)"
										:disabled="currentPage >= totalPages"
									>
										&gt;
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="xpage-search-results-buttons">
						<div
							class="xfilter-button"
							v-if="applications.search.active"
							@click="
								clearSearchbox();
								triggerSearch(null, 1);
							"
						>
							{{ "Search: " + applications.search.text }}
						</div>
						<div
							class="xfilter-button"
							v-for="(item, index) in selectedFilters()"
							@click="clearFilter(item)"
							:key="index"
						>
							{{ item.name }}
						</div>
						<div
							class="xfilter-button"
							v-if="selectedFilters().length + applications.search.active > 1"
							@click="clearAllFiltersAndSearch()"
							data-type="clear"
						>
							Clear All
						</div>
					</div>
					<div
						class="xpage-search-results-data"
						v-auto-animate="{ duration: 100, ...$animate }"
					>
						<div
							class="b-table"
							style="margin-top: 5px"
							v-if="
								filteredAndSortedApplications.length > 0 &&
								typeof filteredAndSortedApplications !== 'string'
							"
							v-auto-animate="{ duration: 100, ...$animate }"
						>
							<div class="table-wrapper">
								<table
									class="table is-fullwidth is-hoverable is-fullwidth xstudentloan"
								>
									<thead>
										<tr>
											<th
												@click="sortData('drawStatusType.name')"
												class="table-header"
											>
												<div class="header-content">
													Status
													<span class="sorting-direction">
														<span
															v-if="sorting.column === 'drawStatusType.name'"
														>
															{{ sorting.direction === "asc" ? "▲" : "▼" }}
														</span>
													</span>
												</div>
											</th>
											<th
												@click="sortData('borrower.firstName')"
												class="table-header"
											>
												<div class="header-content">
													Student
													<span class="sorting-direction">
														<span
															v-if="sorting.column === 'borrower.firstName'"
														>
															{{ sorting.direction === "asc" ? "▲" : "▼" }}
														</span>
													</span>
												</div>
											</th>

											<th @click="sortData('lenderName')" class="table-header">
												<div class="header-content">
													Credit Union
													<span class="sorting-direction">
														<span v-if="sorting.column === 'lenderName'">
															{{ sorting.direction === "asc" ? "▲" : "▼" }}
														</span>
													</span>
												</div>
											</th>

											<th
												@click="sortData('enrollmentTerm.description')"
												class="table-header"
											>
												<div class="header-content">
													Term
													<span class="sorting-direction">
														<span
															v-if="
																sorting.column === 'enrollmentTerm.description'
															"
														>
															{{ sorting.direction === "asc" ? "▲" : "▼" }}
														</span>
													</span>
												</div>
											</th>

											<th
												@click="sortData('borrower.dateOfBirth')"
												class="table-header"
											>
												<div class="header-content">
													DOB
													<span class="sorting-direction">
														<span
															v-if="sorting.column === 'borrower.dateOfBirth'"
														>
															{{ sorting.direction === "asc" ? "▲" : "▼" }}
														</span>
													</span>
												</div>
											</th>

											<th
												@click="sortData('borrower.nationalIdNumber')"
												class="table-header"
											>
												<div class="header-content">
													Tax ID (SSN or Other)
													<span class="sorting-direction">
														<span
															v-if="
																sorting.column === 'borrower.nationalIdNumber'
															"
														>
															{{ sorting.direction === "asc" ? "▲" : "▼" }}
														</span>
													</span>
												</div>
											</th>
											<th
												@click="sortData('requestedAmount')"
												class="table-header"
											>
												<div class="header-content">
													Requested Amount
													<span class="sorting-direction">
														<span v-if="sorting.column === 'requestedAmount'">
															{{ sorting.direction === "asc" ? "▲" : "▼" }}
														</span>
													</span>
												</div>
											</th>
										</tr>
									</thead>
									<template
										v-for="(loan, index) in filteredAndSortedApplications"
										:key="index"
									>
										<tr @click="showDetails(loan, index)">
											<td
												data-label="'Status'"
												:style="getStatusStyle(loan.drawStatusType.name)"
											>
												{{ loan.drawStatusType.name || "No Current Status" }}
											</td>
											<td :data-label="'Student'">
												<div
													class="xusericon"
													data-size="small"
													style="float: left"
												>
													<div class="xusericon-picture">
														{{ loan.initials }}
													</div>
													<div class="xusericon-gender" data-xgender=""></div>
												</div>
												<p
													style="
														margin: 0;
														font-weight: 700;
														font-size: 16px;
														line-height: 36px;
													"
												>
													{{
														`${decodeHTMLEncodedStr(
															loan.borrower.firstName
														)} ${decodeHTMLEncodedStr(loan.borrower.lastName)}`
													}}
												</p>
											</td>
											<td :data-label="'Credit Union'" style="font-weight: 700">
												{{
													decodeHTMLEncodedStr(loan.lenderName) ||
													"No Lender Provided"
												}}
											</td>
											<td :data-label="'Term'" style="font-weight: 700">
												{{ loan.enrollmentTerm.description }}
											</td>
											<td :data-label="'DOB'" style="font-weight: 700">
												{{
													loan.borrower.dateOfBirth
														? $moment(loan.borrower.dateOfBirth).format(
																"MM/DD/YYYY"
															)
														: "—"
												}}
											</td>
											<td
												:data-label="'NationalIdNumber'"
												style="font-weight: 700"
											>
												{{
													loan.borrower.nationalIdNumber
														? loan.borrower.nationalIdNumber
														: "—"
												}}
											</td>
											<td :data-label="'Amount'" style="font-weight: 700">
												{{
													loan.requestedAmount.toLocaleString("en-US", {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													}) || ".00"
												}}
											</td>
										</tr>
										<tr
											v-if="loan.showDetails && loan.disbursements"
											class="detail"
										>
											<td colspan="8" style="background: #f4f5f9">
												<div style="padding: 35px; padding-bottom: 0">
													<comments type="loan" :id="loan.id"></comments>
												</div>
												<div class="xstudentloan-details">
													<div
														class="xtabletop"
														style="
															padding-bottom: 25px;
															margin-bottom: 25px;
															border-bottom: 1px solid #dddddd;
														"
													>
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Address</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 500;
																"
															>
																{{ getAddress(loan) }}
															</h2>
														</div>
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Email Address</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																"
															>
																{{
																	loan.borrower.borrowerContact?.emailAddress ||
																	"—"
																}}
															</h2>
														</div>
														<div class="xtabletop-cell" style="border-right: 0">
															<h3 class="smallcapitals">Phone</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																"
															>
																{{
																	functions.phoneFormat(
																		loan.borrower.borrowerContact
																			?.phoneNumber || ""
																	)
																}}
															</h2>
														</div>
														<div class="xtabletop-cell" style="display: none">
															<h3 class="smallcapitals">Program Code</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																"
															>
																—
															</h2>
														</div>
														<div class="xtabletop-cell" style="display: none">
															<h3 class="smallcapitals">Type</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																"
															>
																—
															</h2>
														</div>
														<div class="xtabletop-cell" style="display: none">
															<h3 class="smallcapitals">NCHER</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 16px;
																	font-weight: 600;
																"
															>
																—
															</h2>
														</div>
													</div>
													<form
														@submit.prevent="validateLoan(loan)"
														autocomplete="off"
													>
														<fieldset>
															<div>
																<div class="xfields">
																	<div class="xfield">
																		<label>
																			Loan Period Start Date
																			<i>*</i>
																		</label>
																		<input
																			type="date"
																			:min="minPeriodDate"
																			:max="maxDate"
																			:disabled="
																				!isValidInitialStatus(
																					loan.drawStatusTypeId
																				)
																			"
																			v-model="loan.loanPeriodStartDate"
																			placeholder="YYYY-MM-DD"
																			required
																		/>
																	</div>
																	<div class="xfield">
																		<label>
																			Loan Period End Date
																			<i>*</i>
																		</label>
																		<input
																			type="date"
																			:min="minPeriodDate"
																			:max="maxDate"
																			:disabled="
																				!isValidInitialStatus(
																					loan.drawStatusTypeId
																				)
																			"
																			v-model="loan.loanPeriodEndDate"
																			placeholder="YYYY-MM-DD"
																			required
																		/>
																	</div>
																	<div class="xfield">
																		<label>
																			Enrollment Status
																			<i>*</i>
																		</label>
																		<select v-model="loan.enrollmentStatus">
																			<option value="1">Full-Time</option>
																			<option value="2">Half-Time</option>
																			<option value="3">
																				Less Than Half-Time
																			</option>
																		</select>
																	</div>
																	<div class="xfield">
																		<label>
																			Term / Grade Level
																			<i>*</i>
																		</label>
																		<select v-model="loan.schoolLevelTypeId">
																			<option
																				v-for="(
																					schoolLevel, schoolLevelIndex
																				) in store.state.types[
																					'SchoolLevelType'
																				].results"
																				:key="schoolLevelIndex"
																				:value="schoolLevel.id"
																			>
																				{{ schoolLevel.name }}
																			</option>
																		</select>
																	</div>
																	<div class="xfield">
																		<label>
																			CLUID
																			<i>*</i>
																		</label>
																		<input
																			type="text"
																			v-model="loan.cluid"
																			pattern=".{17}"
																			title="CLUID must be exactly 17 characters"
																			required
																		/>
																	</div>
																	<div class="xfield">
																		<label>
																			Anticipated Graduation Date
																			<i>*</i>
																		</label>
																		<input
																			type="date"
																			:min="currentDate"
																			:max="maxDate"
																			v-model="loan.anticipatedGraduationDate"
																			placeholder="YYYY-MM-DD"
																			required
																		/>
																	</div>
																	<div v-if="isAdmin" class="xfield">
																		<label>Certified Amount</label>
																		<input
																			type="text"
																			v-model="loan.certifiedAmount"
																			:disabled="
																				certifiedFieldsDisabledByStatus(
																					loan.drawStatusTypeId
																				)
																			"
																		/>
																	</div>
																	<div v-if="isAdmin" class="xfield">
																		<label>School Certification Date</label>
																		<input
																			type="date"
																			placeholder="YYYY-MM-DD"
																			v-model="loan.schoolCertificationDate"
																			:min="minCertificationDate"
																			:max="maxDate"
																			:disabled="
																				certifiedFieldsDisabledByStatus(
																					loan.drawStatusTypeId
																				)
																			"
																		/>
																	</div>
																	<div class="xfield">
																		<!-- empty div to avoid css rabbit hole -->
																	</div>
																</div>
															</div>
															<div>
																<h3>
																	Disbursements
																	<button
																		v-if="
																			loan.disbursements.filter(
																				(d) =>
																					![8, 9].includes(
																						d.disbursementStatusTypeId
																					)
																			).length < 4
																		"
																		class="xregular"
																		@click.prevent="addDisbursement(index)"
																		style="
																			margin-left: 10px;
																			margin-bottom: 10px;
																		"
																	>
																		Add +
																	</button>
																</h3>
																<div
																	class="b-table"
																	style="margin-top: 5px; max-width: 750px"
																>
																	<div class="table-wrapper has-mobile-cards">
																		<table
																			class="table is-fullwidth is-hoverable is-fullwidth xstudentloan"
																		>
																			<thead>
																				<tr>
																					<th style="width: 50px"></th>
																					<th
																						style="
																							width: 60px;
																							text-align: center;
																						"
																					>
																						#
																					</th>
																					<th>Date</th>
																					<th style="width: 150px">Amount</th>
																					<th style="width: 100px">Hold</th>
																				</tr>
																			</thead>
																			<template
																				v-for="(
																					disbursement, disbursementIndex
																				) in loan.disbursements.filter(
																					(d) =>
																						![8, 9].includes(
																							d.disbursementStatusTypeId
																						)
																				)"
																				:key="disbursementIndex"
																			>
																				<tr v-if="!disbursement.deleted">
																					<td
																						data-label="Delete"
																						style="text-align: center"
																					>
																						<div
																							v-if="disbursement.type == 'New'"
																						>
																							<i
																								v-if="
																									[6, 7].includes(
																										disbursement.disbursementStatusTypeId
																									)
																								"
																								class="icon-lock-1"
																								style="font-size: 23px"
																							/>
																							<i
																								v-else
																								class="icon-trash"
																								style="
																									font-size: 23px;
																									color: red;
																								"
																								@click="
																									confirmDeleteDisbursement(
																										loan,
																										disbursement
																									)
																								"
																							/>
																						</div>
																					</td>
																					<td
																						data-label=""
																						style="text-align: center"
																					>
																						<span
																							v-if="disbursement.type != 'New'"
																						>
																							{{ disbursementIndex + 1 }}
																						</span>
																						<span v-else>-</span>
																					</td>
																					<td
																						data-label="Disbursement Date"
																						style="font-weight: 700"
																					>
																						<input
																							type="date"
																							v-model="
																								disbursement.transactionDate
																							"
																							:min="
																								disbursementMinimumDate(
																									disbursement.disbursementStatusTypeId
																								)
																							"
																							:max="maxDate"
																							required
																							placeholder="YYYY-MM-DD"
																							:disabled="
																								[6, 7].includes(
																									disbursement.disbursementStatusTypeId
																								)
																							"
																							style="
																								outline: 2px solid #dddddd;
																								border-radius: 4px;
																							"
																							:class="
																								disbursementDateWarningClass(
																									disbursement
																								)
																							"
																						/>
																					</td>
																					<td data-label="Amount">
																						<i class="dollar">$</i>
																						<input
																							type="text"
																							:value="
																								getDisbursementAmount(
																									disbursement
																								)
																							"
																							@focus="handleFocus"
																							@blur="
																								handleBlur($event, disbursement)
																							"
																							@input="
																								updateAmount(
																									$event,
																									disbursement
																								)
																							"
																							@keyup="calculateTotal(loan)"
																							:class="
																								disbursementAmountWarningClass(
																									loan,
																									disbursement
																								)
																							"
																							:disabled="
																								disbursementDisabledByStatus(
																									disbursement.disbursementStatusTypeId
																								)
																							"
																							style="
																								padding-left: 20px;
																								outline: 2px solid #dddddd;
																								border-radius: 4px;
																							"
																						/>
																					</td>
																					<td data-label="Hold">
																						<input
																							type="checkbox"
																							v-model="
																								disbursement.disbursementStatusCheckbox
																							"
																							:checked="
																								disbursement.disbursementStatusCheckbox
																							"
																							:disabled="
																								disbursementHoldDisabledByStatus(
																									disbursement.disbursementStatusTypeId
																								)
																							"
																						/>
																					</td>
																				</tr>
																			</template>
																			<template
																				v-if="loan.disbursements?.length > 0"
																			>
																				<tr class="footer">
																					<td
																						colspan="3"
																						style="text-align: right"
																					>
																						Requested by Student:&nbsp;
																					</td>
																					<td>
																						${{
																							loan.requestedAmount.toLocaleString(
																								"en-US",
																								{
																									minimumFractionDigits: 2,
																									maximumFractionDigits: 2,
																								}
																							) || ".00"
																						}}
																					</td>
																					<td></td>
																				</tr>
																				<tr class="footer">
																					<td
																						colspan="3"
																						style="text-align: right"
																						:class="{
																							xwarning:
																								loan.disbursementTotal >
																								loan.requestedAmount,
																						}"
																					>
																						Certified Total:&nbsp;
																					</td>
																					<td
																						:class="{
																							xwarning:
																								loan.disbursementTotal >
																								loan.requestedAmount,
																						}"
																					>
																						{{
																							loan.disbursementTotal?.toLocaleString(
																								"en-US",
																								{
																									minimumFractionDigits: 2,
																									maximumFractionDigits: 2,
																								}
																							) || ".00"
																						}}
																					</td>
																					<td></td>
																				</tr>
																			</template>
																			<template v-else>
																				<tr>
																					<td
																						colspan="5"
																						style="text-align: center"
																					>
																						You must add at least one
																						disbursement
																					</td>
																				</tr>
																			</template>
																		</table>
																	</div>
																</div>
															</div>
															<div style="max-width: 750px; display: none">
																<div
																	v-if="
																		loan.disbursementTotal >
																		loan.requestedAmount
																	"
																	class="xwarningbox-small"
																>
																	You have exceeded the requested amount by ${{
																		(
																			loan.disbursementTotal -
																			loan.requestedAmount
																		).toLocaleString("en-US", {
																			minimumFractionDigits: 2,
																			maximumFractionDigits: 2,
																		}) || ".00"
																	}}
																</div>
															</div>
															<div style="margin-top: 30px" v-if="isAdmin">
																<form autocomplete="off">
																	<h3
																		style="
																			text-transform: uppercase;
																			margin-bottom: 15px;
																			font-weight: 800;
																			font-family: 'Montserrat';
																		"
																	>
																		Admin Settings
																	</h3>
																	<div class="xfields">
																		<div class="xfield">
																			<label>Draw/Loan Status</label>
																			<select v-model="loan.drawStatusTypeId">
																				<option
																					v-for="(
																						drawStatus, drawStatusIndex
																					) in store.state.types[
																						'DrawStatusType'
																					].results"
																					:key="drawStatusIndex"
																					:value="drawStatus.id"
																				>
																					{{ drawStatus.name }}
																				</option>
																			</select>
																		</div>
																		<div class="xfield">
																			<label>Certification Method</label>
																			<select
																				v-model="loan.certificationMethodId"
																			>
																				<option
																					v-for="(
																						certificationMethod,
																						certificationMethodIndex
																					) in store.state.types[
																						'CertificationMethod'
																					].results"
																					:key="certificationMethodIndex"
																					:value="certificationMethod.id"
																				>
																					{{ certificationMethod.name }}
																				</option>
																			</select>
																		</div>
																		<div class="xfield"></div>
																	</div>
																</form>
															</div>
															<div>
																<div>
																	<button
																		class="xlarge icon-cancel"
																		data-color="red"
																		@click.prevent="
																			cancelLoan(loan, 'confirmation')
																		"
																	>
																		&nbsp;&nbsp;Terminate
																	</button>
																	<button
																		class="xlarge icon-ok"
																		data-color="green"
																		type="submit"
																	>
																		&nbsp;Update
																	</button>
																</div>
															</div>
														</fieldset>
													</form>
												</div>
											</td>
										</tr>
									</template>
								</table>
							</div>
						</div>
						<div v-if="loading" class="xfoundnothing">
							<h2 style="padding-top: 5px; margin-bottom: 10px">Loading...</h2>
						</div>
						<div v-if="filteredApplications.length === 0" class="xfoundnothing">
							<h2 style="padding-top: 5px; margin-bottom: 10px">
								No matches found
							</h2>
							<b>0</b>
							results
							<span v-if="applications.search.active">
								>for
								<b>"{{ applications.search.text }}"</b>
							</span>
							<span v-if="selectedFilters('text').length > 0">
								with the filters
							</span>
							<b>
								<span>{{ selectedFilters("text") }}</span>
							</b>
						</div>
						<div
							v-if="
								applications.query.totalCount > 30 &&
								typeof applications.data !== 'string'
							"
							style="display: none"
							class="xpagnation"
						>
							<button
								v-for="number in Math.ceil(
									(applications.query.totalCount + pageSize - 1) / pageSize
								)"
								:key="number"
								:class="{
									xselected:
										number === applications.query.pageNumber ||
										(number === 1 && !applications.query.pageNumber),
								}"
								@click="
									triggerSearch(applications.search.typing, number, pageSize)
								"
							>
								{{ number }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Page>
</div>
</template>

<script setup>
import { reactive, onMounted, watch, ref, nextTick, computed } from "vue";
import Loader from "@/components/Loader.vue";
import Page from "@/views/layout/Page.vue";
import store from "@/store";
import { useRouter, useRoute } from "vue-router";
import functions from "@/use/functions.js";
import axios from "axios";
import moment from "moment-timezone";
import comments from "@/components/Comments.vue";
import useFunctions from "@/use/functions1";

const { decodeHTMLEncodedStr } = useFunctions();

const isFocused = ref(false);

const pageName = "Certification Requests";
const isAdmin = store.state.oidcStore.user.school_access === "Yes";
const pageSize = ref(90);
const currentPage = ref(1);
const totalCount = ref(0);

const currentDate = new Date().toISOString().split("T")[0];
const minPeriodDate = moment(currentDate).subtract(1, "y").format("YYYY-MM-DD");
const minCertificationDate = moment(currentDate)
	.subtract(180, "d")
	.format("YYYY-MM-DD");
const maxDate = moment(currentDate).add(10, "y").format("YYYY-MM-DD");

const loading = ref(true);
const route = useRoute();
const router = useRouter();
const applications = reactive({
	data: [],
	dataDropdown: [],
	dropdownData: false,
	filterDisplay: false,
	filterOrder: 0,
	filters: [],
	layout: "boxes",
	modal: false,
	recentlyViewed: false,
	search: {
		text: "",
		type: "",
		typing: "",
		timestamp: 0,
		active: false,
		focus: false,
		error: false,
		dropdown: false,
	},
	query: {
		pageNumber: 1,
		totalCount: 0,
	},
});
const enrollmentTerms = ref([]);
const certificationMethods = ref([]);
const drawStatuses = ref([]);
const selectedEnrollmentTerms = ref([]);
const selectedCertificationMethods = ref([]);
const selectedDrawStatuses = ref([]);

const loadCachedData = () => {
	const drawStatusData = functions.checkLocalStorage("cache.DrawStatusType");
	if (drawStatusData) {
		drawStatuses.value = drawStatusData.data.results;
	}
	const certificationMethodData = functions.checkLocalStorage(
		"cache.CertificationMethod"
	);
	if (certificationMethodData) {
		certificationMethods.value = certificationMethodData.data.results;
	}
	const enrollmentTermData = functions.checkLocalStorage(
		"cache.EnrollmentTerms"
	);
	if (enrollmentTermData) {
		enrollmentTerms.value = enrollmentTermData.data;
	}
};

const filteredApplications = computed(() => {
	if (!Array.isArray(applications.data) || applications.data.length === 0) {
		return [] || "No results found";
	}
	return applications.data;
});

const filteredAndSortedApplications = computed(() => {
	let filtered = filteredApplications.value;
	if (!sorting.column) {
		return filtered;
	}
	return [...filtered].sort((a, b) => {
		const modifier = sorting.direction === "asc" ? 1 : -1;
		let aValue = getNestedValue(a, sorting.column);
		let bValue = getNestedValue(b, sorting.column);

		if (typeof aValue === "number" && typeof bValue === "number") {
			aValue = parseFloat(aValue);
			bValue = parseFloat(bValue);
		} else if (typeof aValue === "string" && typeof bValue === "string") {
			aValue = aValue.toLowerCase();
			bValue = bValue.toLowerCase();
		}

		if (aValue < bValue) return -1 * modifier;
		if (aValue > bValue) return 1 * modifier;
		return 0;
	});
});

const totalPages = computed(() => {
	return Math.ceil(totalCount.value / pageSize.value);
});

const totalPageCountText = computed(() => {
	return `${totalPages.value} ${totalPages.value === 1 ? "page" : "pages"}`;
});

const changePage = (page) => {
	if (page >= 1 && page <= totalPages.value) {
		currentPage.value = page;
		applications.query.pageNumber = page;
		triggerSearch(applications.search.typing, page, pageSize.value);
	}
};

const showDetails = (loan, index) => {
	loan.showDetails = !loan.showDetails;
	if (loan.showDetails) {
		getDisbursements(loan.id, index);
	}
};

function getStatusStyle(statusName) {
	return statusName
		? {
				background: statusName === "Active" ? "white" : "white",
				color: statusName === "Active" ? "black" : "black",
				fontWeight: 700,
		  }
		: {};
}

const handleFocus = () => {
	isFocused.value = true;
};

const clearSearchbox = () => {
	applications.search.text = "";
	applications.search.typing = "";
	if (applications.search.active) {
		applications.search.active = false;
	}
};

const blurSearchbox = () => {
	setTimeout(() => {
		applications.search.focus = false;
	}, 200);
};

const handleBlur = (event, disbursement) => {
	isFocused.value = false;

	if (!event || !event.target || typeof event.target.value === "undefined") {
		return;
	}

	const newValue = parseFloat(event.target.value).toFixed(2) || "";

	if (!isNaN(newValue) && isFinite(newValue)) {
		if (disbursement.type == "New") {
			disbursement.certifiedAmount = newValue;
		} else {
			disbursement.disbursedAmount = newValue;
		}
	}
};

const formatCurrency = (amount) => {
	return isFocused.value || amount ? parseFloat(amount).toFixed(2) : "";
};

const updateAmount = (event, disbursement) => {
	const input = event.target;
	const cursorPosition = input.selectionStart;
	const newValue = parseFloat(input.value);

	if (!isNaN(newValue) && isFinite(newValue)) {
		if (disbursement.type == "New") {
			disbursement.certifiedAmount = newValue;
		} else {
			disbursement.disbursedAmount = newValue;
		}
	}

	nextTick(() => {
		input.selectionStart = cursorPosition;
		input.selectionEnd = cursorPosition;
	});
};

const getDisbursements = async (drawId, drawIndex) => {
	await axios(`/api/loanservice/draws/${drawId}/disbursements`, {
		require: "json",
		method: "GET",
	})
		.then((response) => {
			response.data.disbursements.forEach((disbursement) => {
				// if the disbursement is `on hold` (status type of 3)
				if (disbursement.disbursementStatusTypeId == 3) {
					disbursement.disbursementStatusCheckbox = true;
				}

				disbursement.transactionDate =
					disbursement.transactionDate?.split("T")?.[0] || "";
			});

			applications.data[drawIndex].disbursements = response.data.disbursements;

			calculateTotal(applications.data[drawIndex]);
		})
		.catch((error) => {
			console.log(
				`API Error: /api/loanservice/draws/${drawId}/disbursements`,
				error
			);
		});
};

const updateDraw = async(draw) => {
	const route = "/api/loanservice/draws/update";

	try {
        const response = await axios.post(route, draw);
		console.log("Update draw response status:", response.status);

        return true;
	} catch (error) {
		console.error(`API Error:`, error.response ? error.response.data : error.message);
		return false;
	}
}

const terminateDraw = async (drawId) => {
	const route = `/api/loanservice/draws/${drawId}/terminate`;

	try {
		await axios.patch(route);
		return true;
	} catch (error) {
		console.error(`API Error: ${route}`, error);
	}

	return false;
};

function calculateTotal(loan) {
	let disbursementTotal = 0;
	loan.disbursements
		.filter((d) => !d.deleted && ![8, 9].includes(d.disbursementStatusTypeId))
		.forEach((d) => {
			var amount = d.type == "New" ? d.certifiedAmount : d.disbursedAmount;

			disbursementTotal += Number(amount);
		});
	loan.disbursementTotal = disbursementTotal;
}

function addDisbursement(loanIndex) {
	applications.data[loanIndex].disbursements.push({
		type: "New",
		certifiedAmount: 0.0,
	});
}

const getAddress = (loan) => {
	const { borrowerAddress } = loan.borrower || {};
	return borrowerAddress
		? [
				borrowerAddress.addressLineOne,
				borrowerAddress.addressLineTwo,
				borrowerAddress.city,
				borrowerAddress.addressState?.abbreviation,
				borrowerAddress.postalCode,
		  ]
				.filter((n) => n)
				.join(", ")
		: "Not Provided";
};

const createDisbursements = async (draw) => {
	let newDisbursements = draw.disbursements.filter(
		(disbursement) => disbursement.type == "New"
	);

	draw.disbursements.forEach((disbursement) => {
		if (
			!disbursementHoldDisabledByStatus(disbursement.disbursementStatusTypeId)
		) {
			disbursement.disbursementStatusTypeId =
				disbursement.disbursementStatusCheckbox ? 3 : 5;
		}
	});

	try {
		draw.disbursements
			.filter((disbursement) => disbursement.type != "New")
			.forEach(async (disbursement) => await updateDisbursement(disbursement));

		if (Array.isArray(newDisbursements) && newDisbursements.length > 0) {
			await axios.post(
				`/api/loanservice/draw/${draw.id}/disbursements`,
				newDisbursements
			);
		}

		return true;
	} catch (error) {
		console.log(
			`API Error: /api/loanservice/draw/${draw.id}/disbursements`,
			error
		);
		return false;
	}
};

const updateDisbursement = async (disbursement) => {
	const disbursementClone = JSON.parse(JSON.stringify(disbursement));

	disbursementClone.transactionDate = moment
		.utc(
			moment(disbursementClone.transactionDate).tz(moment.tz.guess()).valueOf()
		)
		.format();

	return await axios(`/api/loanservice/disbursements/update`, {
		require: "json",
		method: "POST",
		data: disbursementClone,
	})
		.then((response) => {
			return true;
		})
		.catch((error) => {
			console.log(`API Error: /api/loanservice/disbursements/update`, error);
			return false;
		});
};

const confirmDeleteDisbursement = (draw, disbursement) => {
	functions.openModal({
		title: "Confirm",
		description: "Are you sure you want to delete this?",
		buttons: [
			{
				title: "Cancel",
				onClick: () => functions.closeModal(),
			},
			{
				title: "Delete",
				onClick: () => deleteDisbursement(draw, disbursement),
			},
		],
	});
};

const deleteDisbursement = (draw, disbursement) => {
	functions.closeModal();

	const disbursementIndex = draw.disbursements.indexOf(disbursement);

	if (disbursementIndex != -1) {
		draw.disbursements.splice(disbursementIndex, 1);

		calculateTotal(draw);
	}
};

const validateLoan = async (loan) => {
	let errors = [];

	if (loan.drawStatusTypeId !== 7) {
		// only validate if not terminating
		loan.disbursements
			.filter(
				(d) =>
					(d.disbursementStatusTypeId === undefined ||
						d.disbursementStatusTypeId == 1) &&
					!d.deleted
			)
			.forEach((disbursement) => {
				disbursement.warning = true;

				if (
					disbursement.certifiedAmount === undefined ||
					disbursement.certifiedAmount < 50
				) {
					errors.push("• The Certified Total must be at least $50");
				} else {
					disbursement.warning = false;
				}

				const transactionDate = moment(disbursement.transactionDate);

				disbursement.dateWarning = true;

				if (!transactionDate.isValid() || transactionDate.isBefore(moment())) {
					errors.push("• The Disbursement Dates must be future dates");
				} else if (transactionDate.isAfter(moment(maxDate))) {
					errors.push(
						"• The Disbursement Dates must not occur after the Loan Period End Date"
					);
				} else {
					disbursement.dateWarning = false;
				}
			});

		if (loan.disbursementTotal > loan.requestedAmount) {
			errors.push(
				"• The Certified Total must be less than or equal to the Requested Amount"
			);
		}
	}

	if (errors.length === 0) {
		loan.disbursements.forEach((d) => {
			// conditionally update 'new' disbursement statuses
			d.disbursementStatusTypeId =
				d.disbursementStatusTypeId == 1
					? d.disbursementStatusCheckbox
						? 3
						: 5
					: d.disbursementStatusTypeId;
		});

		functions.openModal({
			title: "Confirm",
			description: "Are you sure you want to update this loan?",
			buttons: [
				{ title: "Cancel", onClick: () => functions.closeModal() },
				{
					title: "Submit",
					onClick: async () => {
						functions.closeModal();
						await updateLoan(loan);
					},
				},
			],
		});
	} else {
		errors = Array.from(errors);

		functions.openModal({
			title: "Alert",
			description: "Please resolve the following issues before updating:",
			html: `<p>${errors.join("<br/>")}</p>`,
		});
	}
};

const updateLoan = async (loan) => {
	let success = false;

	if (loan.drawStatusTypeId === 7) {
		success = await terminateDraw(loan.id);
	} else if (await createDisbursements(loan)) {
		if (await updateDraw(loan)) {
			success = true;
		}
	}

	if (success) {
		functions.openModal({
			title: "Success",
			description: "Loan has been successfully certified.",
			buttons: [
				{
					title: "Close",
					onClick: () => {
						functions.closeModal();
						loan.showDetails = false;
					},
				},
			],
		});
	} else {
		functions.openModal({
			title: "Error",
			description:
				"There was an error certifying this record. Please try again.",
		});
	}
};

const cancelLoan = async (loan, confirmation) => {
	if (confirmation) {
		functions.openModal({
			title: "Confirm",
			description: "Are you sure you want to cancel this loan?",
			buttons: [
				{ title: "No", onClick: () => functions.closeModal() },
				{ title: "Yes", onClick: () => cancelLoan(loan) },
			],
		});
	} else {
		functions.closeModal();

		await axios(`/api/loanservice/draws/update`, {
			require: "json",
			method: "POST",
			data: {
				id: loan.id,
				drawStatusTypeId: 4, // school unable to certify
				enrollmentTermId: loan.enrollmentTermId,
				certificationMethodId: loan.certificationMethodId,
				schoolLevelTypeId: loan.schoolLevelTypeId,
				requestedAmount: loan.requestedAmount,
			},
		})
			.then(() => {
				functions.openModal({
					title: "Success",
					description: "The loan has been cancelled!",
					buttons: [
						{
							title: "Close",
							onClick: () => {
								functions.closeModal();
								loan.showDetails = false;
							},
						},
					],
				});
			})
			.catch((error) => {
				functions.openModal({
					title: "Error",
					description:
						"There was an issue canceling this loan. Please try again.",
				});

				console.log(`API Error: /api/loanservice/draws/update`, error);
			});
	}
};

const triggerSearch = async (searchText,
	pageNumber = 1,
	size = pageSize.value
) => {
	if (searchText) {
		searchText = functions.cleanSearchText(searchText).toLowerCase();
		applications.search.typing = searchText;
		applications.search.text = searchText;
		applications.search.active = true;
	} else if (applications.search.active && applications.search.text) {
		searchText = functions
			.cleanSearchText(applications.search.text)
			.toLowerCase();
		applications.search.typing = searchText;
		applications.search.text = searchText;
	}

	if (pageNumber) {
		applications.query.pageNumber = pageNumber;
	}
	if (size) {
		pageSize.value = size;
	}

	await searchApplications(searchText, pageNumber, size);
};

const changePageSize = () => {
	currentPage.value = 1;
	triggerSearch(applications.search.typing, 1, pageSize.value);
};

async function searchApplications(searchText, pageNumber, pageSize) {
	applications.data = "Loading...";
	applications.search.timestamp = Date.now();
	const filters = {
		enrollmentTermIds: selectedEnrollmentTerms.value,
		drawStatusIds: selectedDrawStatuses.value,
		certificationMethodIds: selectedCertificationMethods.value,
	};

	const response = await functions.getDraws(
		searchText,
		filters,
		pageNumber,
		pageSize
	);

	if (response) {
		const uniqueData = filterUnique(response.data.items);
		applications.data = uniqueData;
		applications.query = {
			pageNumber: response.data.page.index,
			totalCount: response.data.totalCount,
		};
		totalCount.value = response.data.totalCount;
		currentPage.value = response.data.page.index;
	} else {
		applications.data = "Error Loading Results";
	}
}

function  filterUnique(data) {
	const seenIds = new Set();
	return data.filter((application) => {
		if (seenIds.has(application.id)) {
			return false;
		} else {
			seenIds.add(application.id);
			return true;
		}
	});
}

	const searchDropdown = async (searchText) => {
	searchText = functions.cleanSearchText(searchText).toLowerCase();
	applications.search.typing = searchText;
	applications.search.timestamp = Date.now();

	try {
		const response = await functions.getDraws(searchText);

		if (response && response.data && Array.isArray(response.data.data)) {
			applications.dataDropdown = response.data.data;
		} else {
			applications.dataDropdown = false;
		}
	} catch (error) {
		applications.dataDropdown = false;
		console.log("Error: searchDropdown()", error);
	}
};

const getParametersFromUrl = async () => {
	clearAllFilters();
	if (Object.keys(route.query).length > 0) {
		Object.entries(route.query).forEach(([key, value]) => {
			if (Array.isArray(JSON.parse(value))) {
				const values = JSON.parse(value);
				if (key == "SearchTerms") {
					applications.search.active = true;
					applications.search.text = values.join(" ");
					applications.search.typing = values.join(" ");
				} else {
					values.forEach((value) => {
						const selectedFilter = applications.filters.find(
							(f) => f.param == key
						);
						if (selectedFilter) {
							selectedFilter.results.forEach((result) => {
								if (selectedFilter.type == "radio") {
									selectedFilter.results.forEach((item) => {
										item.checked = false;
									});
									selectedFilter.results.filter(
										(f) => f.id == value
									)[0].checked = true;
								} else if (result.children) {
									result.children.forEach((child) => {
										if (Number(child.id) === Number(value)) {
											child.checked = true;
										}
									});
									result.checked = result.children.every(
										(child) => child.checked
									);
								} else if (Number(result.id) === Number(value)) {
									result.checked = true;
								}
							});
						}
					});
				}
			} else {
				if (key == "SearchTerm") {
					applications.search.active = true;
					applications.search.text = value;
					applications.search.typing = value;
				} else if (key == "PageNumber") {
					applications.query.pageNumber = Number(value);
				}
			}
		});
	}
};

const clearFilter = (item) => {
	item.checked = false;
	if (item.children) {
		item.children.forEach((child) => {
			child.checked = false;
		});
	}
	triggerSearch(applications.search.text, 1);
};

const clearAllFiltersAndSearch = () => {
	router.push("");
};

const clearAllFilters = () => {
	applications.filters.forEach((section) => {
		section.results.forEach((field) => {
			field.checked = false;
			field.children?.forEach((child) => {
				child.checked = false;
			});
		});
	});
	clearSearchbox();
};

const selectedFilters = (output) => {
	let data = [];
	applications.filters
		.filter((section) => section.type != "radio")
		.forEach((section) => {
			section.results.forEach((field) => {
				if (field.children && !field.checked) {
					field.children.forEach((child) => {
						if (child.checked) {
							child["section"] = section.description;
							data.push(child);
						}
					});
				} else if (field.checked) {
					field["section"] = section.description;
					data.push(field);
				}
				if (field.children) {
					field.checked = field.children.every((child) => child.checked);
				}
			});
		});
	data.sort((a, b) => {
		return a.filterOrder - b.filterOrder;
	});

	if (output == "text") {
		data = data
			.map((field) => {
				return `"${field.name}"`;
			})
			.join(" or ");
	}
	return data;
};

const getDisbursementAmount = (disbursement) => {
	const amount =
		disbursement.type == "New"
			? disbursement.certifiedAmount
			: disbursement.disbursedAmount;
	return isFocused.value ? formatCurrency(amount) : amount;
};

const disbursementAmountWarningClass = (draw, disbursement) => {
	const amount =
		disbursement.type == "New"
			? disbursement.certifiedAmount
			: disbursement.disbursedAmount;
	const invalidAmount = amount < 50 || amount > 99999;
	const invalidTotal = draw.disbursementTotal > draw.requestedAmount;
	return invalidAmount || invalidTotal ? "xwarning" : "";
};

const disbursementDateWarningClass = (disbursement) => {
	const currentDate = moment();
	const transactionDate = moment(disbursement.transactionDate);
	const invalid =
		!transactionDate.isValid() || transactionDate.isBefore(currentDate);
	return disbursement.type == "New" && invalid ? "xwarning" : "";
};

const disbursementMinimumDate = (disbursementStatusTypeId) => {
	return disbursementStatusTypeId === undefined || disbursementStatusTypeId == 1
		? currentDate
		: null;
};

const disbursementDisabledByStatus = (disbursementStatusTypeId) => {
	return [6, 7, 8, 9].includes(disbursementStatusTypeId);
};

const certifiedFieldsDisabledByStatus = (drawStatusTypeId) => {
	return [1, 5, 6, 7, 9].includes(drawStatusTypeId);
};

const disbursementHoldDisabledByStatus = (drawStatusTypeId) => {
	return [2, 6, 7, 8, 9].includes(drawStatusTypeId);
};

const isValidInitialStatus = (drawStatusTypeId) => {
	return isAdmin || drawStatusTypeId === 1 || drawStatusTypeId === 9;
};

const clearSelection = (section) => {
	if (section === "enrollmentTerms") {
		selectedEnrollmentTerms.value = [];
	} else if (section === "certificationMethods") {
		selectedCertificationMethods.value = [];
	} else if (section === "drawStatuses") {
		selectedDrawStatuses.value = [];
	}
	triggerSearch(applications.search.text, 1);
};

const sorting = reactive({
	column: null,
	direction: "asc",
});

function sortData(column) {
	if (sorting.column === column) {
		sorting.direction = sorting.direction === "asc" ? "desc" : "asc";
	} else {
		sorting.column = column;
		sorting.direction = "asc";
	}
}

function getNestedValue(obj, path) {
	return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}

watch(selectedEnrollmentTerms, () => {
	triggerSearch(applications.search.text, 1);
});

watch(selectedCertificationMethods, () => {
	triggerSearch(applications.search.text, 1);
});

watch(selectedDrawStatuses, () => {
	triggerSearch(applications.search.text, 1);
});

watch(
	() => route.query,
	() => {
		getParametersFromUrl();
		searchApplications();
	}
);

watch(pageSize, () => {
	triggerSearch(applications.search.text, currentPage.value, pageSize.value);
});

onMounted(async () => {
	await loadCachedData();
	await functions.getType("DrawStatusType");
	await functions.getType("CertificationMethod");
	await functions.getType("SchoolLevelType");
	await functions.getEnrollmentTerms();
	searchApplications();
	loading.value = false;

	if (localStorage.getItem("cache.RecentlyViewedPages")) {
		applications.recentlyViewed = JSON.parse(
			localStorage.getItem("cache.RecentlyViewedPages")
		).filter((item) => item.page == pageName.toLowerCase());
	}
});
</script>

<style scoped>
.xpage-content-wrapper {
	display: flex;
}
.xpage-search-filters {
	background-color: #f8f8f8;
	padding: 20px;
	width: 290px;
	border-radius: 10px;
	height: fit-content;
	margin-top: 5px;
}

.table-header {
	width: 200px;
	/* justify-content: space-between; */
}

.header-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.sorting-direction {
	margin-left: auto;
	padding-left: 10px;
}

.table-data {
	width: 85%;
	margin-left: 20px;
}

.clearButton {
	background-color: #f8f8f8;
	border: 1px solid #444444;
	border-radius: 5px;
	padding: 5px 10px;
	margin-top: 10px;
	font-weight: bold;
}

.clearButton:hover {
	background-color: #333333;
	color: white;
	font-weight: bold;
}

.page-size-selector select {
	padding: 5px 10px;
	font-size: 14px;
}

.page-size-selector {
	margin-left: 10px;
	display: inline-block;
	margin-right: 10px;
}

.xpage-searchbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
}

.results-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;
	line-height: 44px;
	margin-right: 10px;
	font-family: Montserrat, arial, sans-serif;
	font-size: 19px;
	font-weight: 800;
	border-bottom: 1px solid rgb(183, 183, 183);
}

.results-info-pages {
	line-height: 20px;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 700;
	margin-left: 10px;
}

.pagination-controls {
	display: inline-block;
}

.pagination button:disabled {
	border-color: #eaeaea;
	cursor: not-allowed;
	color: #ccc;
}

.pagination .xclicked {
	background-color: #000;
	color: #fff;
}

.pagination span {
	padding: 5px 10px;
}

.pagination ellipsis {
	padding: 5px 10px;
}
</style>
