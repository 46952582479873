<template>
	<Loader v-if="loading"></Loader>
	<template v-else>
		<Header></Header>
		<Sidebar></Sidebar>
		<div class="xpage">
			<slot></slot>
		</div>
		<Modal></Modal>
	</template>
</template>

<script setup>
	import { onMounted } from 'vue';
	import Loader from '@/components/Loader.vue';
	import Header from '@/views/layout/Header.vue';
	import Sidebar from '@/views/layout/Sidebar.vue';
	import Modal from '@/components/Modal.vue';

	var loading = $ref(true);

	onMounted(async () => {
		loading = false;
	});
</script>
