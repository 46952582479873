<template>
	<loader v-if="application.loading" />
	<page v-else>
		<div v-if="application.data">
			<div class="xpage-breadcrumbs">
				<router-link to="/dashboard" class="icon-home"></router-link>
				<span>></span> <router-link to="/loans">Loans</router-link>
				<span>></span>
				<router-link :to="`/loans/${route.params.referenceId}`">{{
					borrowers['primary'].fullName
				}}</router-link>
			</div>
			<div class="xpage-top">
				<div class="xpage-top-left">
					<div class="xpage-top-profile">
						<div class="xpage-top-profile-icon">
							{{ borrowers['primary'].initials }}
						</div>
						<div class="xpage-top-profile-gender" data-gender=""></div>
					</div>
					<div style="padding-top: 7px">
						<h2>{{ decodeHTMLEncodedStr(borrowers['primary'].fullName) || 'No Name'}}</h2>
					</div>
					<h3>{{ application.data.lenderName || 'No Lender Provided' }}</h3>
					<div class="xpage-top-details-status">
						<h4>{{ application.data.loanStatusType?.name }}</h4>
					</div>
					<div class="clear"></div>
				</div>

				<div class="xcollapse-mobile">
					<a
						href="#"
						:class="[
							application.mobileHidden
								? 'icon-down-open-big'
								: 'icon-up-open-big',
						]"
						@click.prevent="toggle('application.mobileHidden')"></a>
				</div>

				<div
					class="xpage-top-right"
					:class="{ xhideformobile: application.mobileHidden }">
					<div class="xpage-top-right-col">
						<h2>Enrollment Status</h2>
						<h3>Full Time</h3>
					</div>
					<div class="xpage-top-right-col">
						<h2>Anticipated Graduation</h2>
						<h3>
							{{
								$moment(
									application.data.draws?.[0]?.anticipatedGraduationDate
								).format('MMMM YYYY')
							}}
						</h3>
					</div>
					<div class="xpage-top-right-col">
						<h2>Headline</h2>
						<h3>
							{{
								(application.data.loanConfiguration?.indexRate || '') +
								(application.data.loanConfiguration?.margin || '')
									? `${
											(application.data.loanConfiguration?.indexRate || '') +
											(application.data.loanConfiguration?.margin || '')
									  }%`
									: '—'
							}}
						</h3>
					</div>
				</div>
				<div class="clear"></div>
			</div>
			<div class="xpage-overview-collapse">
				<a
					href="#"
					class="xcollapse-desktop"
					:class="[
						application.showDetails ? 'icon-up-open-big' : 'icon-down-open-big',
					]"
					@click.prevent="toggleApplicationDetails()"></a>
			</div>
			<div class="xpage-overview">
				<slideDown :class="{ xhideformobile: application.mobileHidden }">
					<div class="xpage-overview-cols" v-show="application.showDetails">
						<div class="xpage-overview-cols-inner">
							<div class="xpage-overview-col" data-type="details">
								<div class="xdetails-table">
									<div class="xdetails-col">
										<div class="xname">Loan ID</div>
										<div class="xtext">
											{{
												application.data.id
													? application.data.id
													: 'Not Provided'
											}}
										</div>
									</div>
									<div class="xdetails-col">
										<div class="xname">Created</div>
										<div class="xtext">
											{{
												$moment(`${application.data.loanStatusAtUtc}`)
													.tz(localTimezone)
													.format('MMMM D, YYYY [at] h:mm A z')
											}}
										</div>
									</div>
								</div>
							</div>
							<div class="xpage-overview-col" data-type="applicant">
								<table>
									<tr>
										<td class="xname">Phone</td>
										<td class="xtext">
											{{
												borrowers['primary'].borrowerContact?.phoneDisplay ||
												'Not Provided'
											}}
											<span v-if="borrowers['primary'].contactInfo?.phoneNumber"
												>({{
													borrowers['primary'].contactInfo?.phoneNumberTypeName
												}})</span
											>
										</td>
									</tr>
									<tr>
										<td class="xname">Email</td>
										<td class="xtext">
											{{
												borrowers[
													'primary'
												].borrowerContact?.emailAddress?.toLowerCase() ||
												'Not Provided'
											}}
										</td>
									</tr>
									<tr>
										<td class="xname">SSN</td>
										<td class="xtext">
											{{
												borrowers['primary'].ssn
													? `XXX-XX-${borrowers['primary'].ssn}`
													: 'Not Provided'
											}}
										</td>
									</tr>
								</table>
							</div>
							<div
								class="xpage-overview-col"
								data-type="applicant"
								v-if="borrowers['secondary']">
								<table>
									<tr>
										<td class="xname">Birthdate</td>
										<td class="xtext">
											{{
												$moment(borrowers['secondary']?.dateOfBirth).format(
													'MMMM D, YYYY'
												)
											}}
										</td>
									</tr>
									<tr>
										<td class="xname">Address</td>
										<td class="xtext" style="text-transform: capitalize">
											{{
												[
													borrowers['secondary'].borrowerAddress.addressLineOne,
													borrowers['secondary'].borrowerAddress.addressLineTwo,
													borrowers['secondary'].borrowerAddress.city,
													borrowers['secondary'].borrowerAddress.addressState
														.name,
													borrowers['secondary'].borrowerAddress.postalCode,
													borrowers['secondary'].borrowerAddress.addressCountry
														.name,
												]
													.filter((n) => n)
													.join(', ') || 'Not Provided'
											}}
										</td>
									</tr>
								</table>
							</div>
							<div
								class="xpage-overview-col"
								data-type="missing-coapplicant"
								v-else>
								<div>This loan has no Co-Borrower</div>
							</div>
							<div class="clear"></div>
						</div>
					</div>
				</slideDown>
			</div>

			<div
				class="xpage-content"
				v-auto-animate="{ ...$animate, duration: 100 }"
				v-if="application.data">
				<div
					class="xpage-content-page"
					id="xapplication-tab"
					v-auto-animate="$animate">
					<div class="xtitlearea">
						<h2 style="float: none">Draws</h2>
					</div>

					<div>
						<div class="b-table" style="margin-top: 5px">
							<div class="table-wrapper has-mobile-cards">
								<table
									class="table is-fullwidth is-hoverable is-fullwidth xstudentloan">
									<thead>
										<tr>
											<th style="width: 110px">Draw ID</th>
											<th style="width: 80px">Amount</th>
											<th>Period</th>
											<th style="width: 110px">Disbursements</th>
											<th style="width: 210px">Status</th>
											<th style="width: 180px">Created</th>
										</tr>
									</thead>
									<template
										v-for="(income, index) in application.incomeData"
										:key="index">
										<tr @click="income.showDocument = !income.showDocument">
											<td data-label="Draw ID">9005551571</td>
											<td data-label="Amount">$26,000</td>
											<td data-label="Period">
												<p style="margin: 0; font-weight: 700; font-size: 16px">
													Jan 2021 — Mar 2025
												</p>
												<p
													style="
														margin: 0;
														margin-top: 2px;
														text-transform: uppercase;
														font-size: 11px;
														color: #adadad;
													">
													Lake Trust Credit Union
												</p>
											</td>
											<td data-label="Disbursements">1 of 4</td>
											<td data-label="Status">
												<div
													style="
														text-align: center;
														background: #fff6e0;
														padding: 7px 10px;
														border-radius: 4px;
														border: 1px solid #ffc539;
														font-weight: 700;
														font-size: 13px;
													">
													Pending Certification
												</div>
											</td>
											<td data-label="Total">2010-10-10</td>
										</tr>
										<tr v-if="income.showDocument" class="detail">
											<td colspan="7">
												<div class="xstudentloan-details">
													<div class="xtabletop">
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Amount</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 34px;
																	font-weight: 500;
																">
																$26,000
															</h2>
														</div>
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Lender</h3>
															<h2
																style="
																	margin-bottom: 0;
																	font-size: 24px;
																	font-weight: 600;
																	line-height: 41px;
																">
																Lake Trust Credit Union
															</h2>
														</div>
														<div class="xtabletop-cell">
															<h3 class="smallcapitals">Status</h3>
															<h2
																style="
																	font-size: 16px;
																	background: #fff6e0;
																	border: 1px solid #ffc539;
																	padding: 7px 14px;
																	font-weight: 700;
																	border-radius: 5px;
																	margin-bottom: 0;
																">
																Pending Certification
															</h2>
														</div>
													</div>

													<form
														@submit.prevent="confirmApplication()"
														autocomplete="off">
														<fieldset>
															<div class="xform-section">
																<h3>Loan Details</h3>
																<div class="xfields">
																	<div class="xfield">
																		<label>Start Date<i>*</i></label>
																		<input
																			type="date"
																			placeholder="YYYY-MM-DD"
																			required />
																	</div>
																	<div class="xfield">
																		<label>End Date<i>*</i></label>
																		<input
																			type="date"
																			placeholder="YYYY-MM-DD"
																			required />
																	</div>
																	<div class="xfield">
																		<label>Enrollment Status<i>*</i></label>
																		<select>
																			<option
																				v-for="(citizen, index) in store.state
																					.types['Citizenship']?.results"
																				:key="index"
																				:value="citizen.id">
																				Full-Time
																			</option>
																		</select>
																	</div>
																	<div class="xfield">
																		<label>Requested Loan Amount<i>*</i></label>
																		<input type="text" disabled />
																	</div>
																	<div class="xfield">
																		<label>Certified Loan Amount<i>*</i></label>
																		<input type="text" />
																	</div>
																	<div class="xfield">
																		<label>Grade Level<i>*</i></label>
																		<select>
																			<option
																				v-for="(citizen, index) in store.state
																					.types['Citizenship']?.results"
																				:key="index"
																				:value="citizen.id">
																				Freshman
																			</option>
																		</select>
																	</div>
																	<div class="xfield">
																		<label>CLUID<i>*</i></label>
																		<input type="text" />
																	</div>
																	<div class="xfield">
																		<label
																			>Anticipated Graduation Date<i
																				>*</i
																			></label
																		>
																		<input
																			type="date"
																			placeholder="YYYY-MM-DD"
																			required />
																	</div>
																	<div class="xfield">
																		<label>Field<i>*</i></label>
																		<input type="text" />
																	</div>
																</div>
															</div>

															<div class="xform-section">
																<h3>
																	Disbursements
																	<button
																		class="xregular"
																		style="
																			margin-left: 10px;
																			margin-bottom: 0px;
																		">
																		Add <i class="icon-down-open-big"></i>
																	</button>
																</h3>
																<div class="xdisbursements">
																	<div
																		class="xdisbursement"
																		v-for="(
																			disbursement, disbursementIndex
																		) in [1, 2, 3]"
																		:key="disbursementIndex">
																		<h2>
																			Disbursement {{ disbursementIndex + 1 }}
																		</h2>
																		<div class="xdisbursement-field">
																			<label>Date</label>
																			<input type="text" />
																		</div>
																		<div class="xdisbursement-field">
																			<label>Amount<i>*</i></label>
																			<input type="text" required />
																		</div>
																		<div class="xdisbursement-field">
																			<label>Action</label>
																			<input type="text" />
																		</div>
																		<div class="xdisbursement-field">
																			<label>Hold / Release</label>
																			<select disabled>
																				<option
																					v-for="(citizen, index) in store.state
																						.types['Citizenship']?.results"
																					:key="index"
																					:value="citizen.id">
																					{{ citizen.name }}
																				</option>
																			</select>
																		</div>
																	</div>
																</div>
															</div>

															<div class="xform-section">
																<button
																	class="xedit"
																	style="margin: auto; float: none">
																	Save Changes
																</button>
															</div>
														</fieldset>
													</form>
												</div>
											</td>
										</tr>
									</template>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="xpage-breadcrumbs">
				<router-link to="/dashboard" class="icon-home"></router-link>
				<span>></span> <router-link to="/loans">Loans</router-link>
			</div>
			<div class="xwarningbox" style="margin: 30px">
				The Loan ID {{ route.params.referenceId }} failed to load or does not
				exist
			</div>
		</div>
	</page>
</template>

<script setup>
	import { reactive, onMounted, computed, watch } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
	import store from '@/store';
	import page from '@/views/layout/Page.vue';
	import loader from '@/components/Loader.vue';
	import slideDown from '@/components/SlideDown.vue';
	import axios from 'axios';
	import functions from '@/use/functions.js';
	import useFunctions from '@/use/functions1';

	const { decodeHTMLEncodedStr } = useFunctions();

	//////////////
	// vue data //
	//////////////

	var route = useRoute();
	var router = useRouter();
	var application = reactive({
		commentData: [],
		currentTab: '',
		creditReport: false,
		data: false,
		documents: false,
		focusComments: false,
		formDisabled: true,
		formSaved: false,
		identitySelected: false,
		identityDocuments: false,
		incomeData: [
			{ showDocument: false },
			{ showDocument: false },
			{ showDocument: false },
		],
		loading: true,
		mobileHidden: true,
		newComment: '',
		notifications: {
			application: [],
			income: [],
			identity: [],
			esign: [],
			membership: [],
		},
		ratePlanData: [{}],
		showComments: false,
		showDetails: false,
		showOwnerDropdown: false,
		types: {},
		tabs: {
			application: 'incomplete',
			income: 'incomplete',
			identity: 'incomplete',
			esign: 'incomplete',
			membership: 'incomplete',
		},
	});

	var borrowers = reactive({
		primary: false,
		secondary: false,
		backup: {},
		selected: 'primary',
	});

	/////////////////
	// vue methods //
	/////////////////

	async function getLoan() {
		await axios(
			`/api/loanservice/loans/details/ref/${route.params.referenceId}`,
			{ require: 'json' }
		)
			.then((response) => {
				// set the application data equal to the response data for application
				application.data = response.data;

				// if the lenders api has loaded
				if (store.state.types['Lenders']) {
					// set the lender name
					application.data.lenderName =
						store.state.types['Lenders'].find(
							(lender) => lender.id == application.data.lenderId
						)?.name || '';
				}

				// for each of the borrowers
				response.data.borrowers.forEach((borrower) => {
					// if the borrower does not have the following data models, create them as blank objects/arrays so they can be used in vue v-model
					borrower.contactInfo = borrower.contactInfo || {};
					borrower.membership = borrower.membership || {};
					borrower.addresses = decodeHTMLEncodedStr(borrower.addresses) || [{}];

					// create a full name for the borrower, use "no name" if both first/last names are blank
					borrower.fullName =
						`${(decodeHTMLEncodedStr(borrower.firstName)) || ''} ${(decodeHTMLEncodedStr(borrower.lastName)) || ''}`.trim() ||
						'No Name';

					// create initials for the borrower from the first character of each names
					borrower.initials = `${borrower.firstName?.charAt(0) || ''}${
						borrower.lastName?.charAt(0) || ''
					}`.trim();

					if (borrower.borrowerContact) {
						// create a phone number display for the borrower
						borrower.borrowerContact.phoneDisplay = functions.phoneFormat(
							borrower.borrowerContact?.phoneNumber || ''
						);
					}

					// if the borrowerTypeId is "1" then the borrower is "primary", otherwise the borrower is "secondary"
					var borrowerType =
						borrower.borrowerTypeId == 1 ? 'primary' : 'secondary';

					// set the borrower data based on the borrowerType
					borrowers[borrowerType] = borrower;
				});

				// determine the total draw amount
				application.data.totalDrawAmount = 0;

				// for each of the draws
				response.data.draws.forEach((draw) => {
					// the total draw amount does not include draws which are terminated (id 7) or school unable to certify (id 4)
					if (draw.drawStatusTypeId != 7 && draw.drawStatusTypeId != 4) {
						// add the draw amount to the total draw amount
						application.data.totalDrawAmount =
							application.data.totalDrawAmount + Number(draw.requestedAmount);
					}
				});

				// get the school code from the latest draw
				var schoolCode =
					response.data.draws?.[response.data.draws?.length - 1]?.schoolCode ||
					null;

				if (schoolCode) {
					axios(`/api/schools/${schoolCode}`, { require: 'json' })
						.then((response) => {
							application.schoolData = response.data;
						})
						.catch((error) => {
							console.log(`API Error: /api/schools/${schoolCode}`, error);
						});
				}
			})
			.catch((error) => {
				console.log(
					`API Error: /api/loanservice/loans/details/${route.params.referenceId}`,
					error
				);
			});
	}

	function toggle(key) {
		// toggles the input variable true/false
		eval(`${key}=!${key}`);
	}

	function toggleApplicationDetails() {
		toggle('application.showDetails');

		if (localStorage.getItem('cache.applicationDetailsDisplay') == 'false') {
			// set the data
			localStorage.setItem('cache.applicationDetailsDisplay', true);
		} else {
			// set the data
			localStorage.setItem('cache.applicationDetailsDisplay', false);
		}
	}

	//////////////////////////////////////////////////////////////
	// vue watch - executes each time the url parameters change //
	//////////////////////////////////////////////////////////////

	watch(
		() => route.params.referenceId,
		() => {
			// refresh the page
			router.go(0);
		}
	);

	/////////////////////////////////////////////////////////////////
	// vue onMounted - executes when the page is finished mounting //
	/////////////////////////////////////////////////////////////////

	onMounted(async () => {
		functions.getLenders();
		await getLoan();

		application.loading = false;

		setTimeout(function () {
			// if the localstore has cache.applicationDetailsDisplay and it's not set to false
			if (localStorage.getItem('cache.applicationDetailsDisplay') !== 'false') {
				application.showDetails = true;
			}
		}, 5);

		functions.logPageView({
			id: application.data.clientId,
			page: 'loans',
			category: 'Loan',
			timestamp: Date.now(),
			firstName: decodeHTMLEncodedStr(borrowers['primary'].firstName),
			lastName: decodeHTMLEncodedStr(borrowers['primary'].lastName),
			name: decodeHTMLEncodedStr(borrowers['primary'].fullName),
			lenderId: application.data.lenderId,
		});
	});
</script>
