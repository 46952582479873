import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "../store";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Certifications from "../views/Certifications.vue";
import Certification from "../views/Certification-Single.vue";
import Students from "../views/Students.vue";
import Student from "../views/Student-Single.vue";
import Reports from "../views/Reports.vue";
import Disbursements from "../views/Disbursements.vue";
import OidcCallback from "../views/OidcCallback.vue";
import OidcCallbackError from "../views/OidcCallbackError.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		meta: {
			title: `Home`
		},
		component: Home,
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		meta: {
			title: `Dashboard`
		},
		component: Dashboard,
	},
	{
		path: "/certifications",
		name: "Certifications",
		meta: {
			title: "Certifications"
		},
		component: Certifications,
	},
	{
		path: "/certifications/:referenceId",
		name: "Certification",
		meta: {
			title: `Certification`
		},
		component: Certification,
	},
	{
		path: "/students",
		name: "Students",
		meta: {
			title: "Students"
		},
		component: Students,
	},
	{
		path: "/students/:referenceId",
		name: "Student",
		meta: {
			title: `Student`
		},
		component: Student,
	},
	{
		path: "/reports",
		name: "Reports",
		meta: {
			title: "Reports"
		},
		component: Reports,
	},
	{
		path: "/disbursements",
		name: "Disbursements",
		meta: {
			title: "Disbursements"
		},
		component: Disbursements,
	},
	{
		path: "/loggedout",
		name: "loggedout",
		component: () =>
			import("../views/LoggedOut.vue"),
		meta: {
			isPublic: true,
			title: "Logged Out",
		},
	},
	{
		path: "/logout",
		name: "logout",
		component: () =>
			import("../views/Logout.vue"),
		meta: {
			isPublic: true,
			title: "Log Out",
		},
	},
	{
		// needs to match redirectUri in oidcSettings
		path: "/oidc-callback",
		name: "oidcCallback",
		component: OidcCallback,
		meta: {
			isPublic: true,
			title: "Redirecting...",
		},
	},
	{
		// needs to match redirect_uri in oidcSettings
		path: "/oidc-callback-error", 
		name: "oidcCallbackError",
		component: OidcCallbackError,
		meta: {
			isPublic: true,
			title: "Error",
		},
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		const app = document.getElementById("app");
		if (app) {
			app.scrollIntoView();
		}
	},
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

export default router;