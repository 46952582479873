import DOMPurify from "dompurify";

export default function useFunctions(): any {
	function decodeHTMLEncodedStr(text: string) {
		const textArea = document.createElement('textarea');
		if (text) {
		  // Extra pre-processing to remove {{ }} from content prior to sanitizing
		  // potential limitation is if emails or passwords have curly braces...need to determine how we want to handle this -LS06302023
		  const newText = (text || "").replaceAll(/\{|\}/gi, '');

		  // remove amp;
		  const cleanedText = newText.replaceAll("amp;", '');

		  textArea.innerHTML = DOMPurify.sanitize(cleanedText);
		  return textArea.value;
		}
	  }
	  return { decodeHTMLEncodedStr }
}