<template>
	<searchpage pageName="Reports" :filters="filters"></searchpage>
</template>

<script setup>
	import searchpage from '@/views/layout/SearchPage.vue';

	var filters = [
		{
			description: 'Status',
			param: 'ProductIds',
			type: 'radio',
			results: [
				{
					id: 'skip',
					name: 'All',
					checked: true,
				},
				{
					id: 1,
					name: 'Pending Certifications',
					checked: false,
				},
				{
					id: 2,
					name: 'On Hold',
					checked: false,
				},
			],
		},
	];
</script>
