import axios from "axios";
import moment from "moment";
import store from "../store";
import useFunctions from "./functions1";

const { decodeHTMLEncodedStr } = useFunctions();

function isValidSSN(ssn) {
	// remove all hyphens and spaces from the ssn
	ssn = ssn?.replace(/[- ]/g, "");

	// a valid 9 digit ssn is [3 digits not including 000, 666, 900-999][2 digits 01-99][4 digits 0001-9999]
	var regex = new RegExp(/^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/);

	// check if the ssn is valid using the regular expression, return true or false
	return regex.test(ssn);
}

function phoneFormat(phone) {
	// if the phone number length is 10 digits, then add dashes to it, otherwise return the phone unchanged
	return phone.length === 10
		? `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`
		: phone;
}

function ssnFormat(ssn) {
	// if the ssn length is 9 digits and has five stars, change the ssn format to XXX-XX-0000
	return ssn?.length === 9 && ssn?.includes("*****")
		? ssn.replace("*****", "XXX-XX-")
		: ssn;
}

function capitalize(input) {
	// this capitalizes each word of a string
	return input
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.substring(1))
		.join(" ");
}

function compareObjects(obj1, obj2, path = []) {
	////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// description: takes an input of two objects and returns an array of all the differences between the two //
	////////////////////////////////////////////////////////////////////////////////////////////////////////////

	let mismatchedKeys = [];

	if (typeof obj1 !== typeof obj2) {
		return mismatchedKeys;
	}

	if (typeof obj1 !== "object" || obj1 === null) {
		if (obj1 !== obj2) {
			mismatchedKeys.push(path);
		}
		return mismatchedKeys;
	}

	for (const key of Object.keys(obj1)) {
		let newPath = [...path, key];
		// eslint-disable-next-line no-prototype-builtins
		if (!obj2.hasOwnProperty(key)) {
			mismatchedKeys.push(newPath);
		} else {
			mismatchedKeys = [
				...mismatchedKeys,
				...compareObjects(obj1[key], obj2[key], newPath),
			];
		}
	}
	return mismatchedKeys;
}

function getLenderName(lenderId) {
	if (store.state.types["Lenders"]) {
		return (
			store.state.types["Lenders"].filter(
				(lender) => lender.id === Number(lenderId)
			)?.[0]?.name || "No Lender Provided"
		);
	} else {
		return "No Lender Provided";
	}
}

function displayProductType(typeId) {
	if (store.state.types["Products"]) {
		return (
			store.state.types["Products"].results.filter(
				(product) => product.id == typeId
			)?.[0]?.name || ""
		);
	} else {
		return "";
	}
}

function checkFormChanges(object1, object2) {
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// description: returns an html string of changes in a form by comparing the before object with the after object //
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	var html = "";

	compareObjects(object1, object2).forEach((reference) => {
		var before = reference.reduce((o, key) => o[key], object2);
		var after = reference.reduce((o, key) => o[key], object1);

		// get the last variable from the reference array, and convert the camelCase to add spaces
		var name = reference.pop().replace(/([a-z])([A-Z])/g, `$1 $2`);

		html += `<tr><td class="xbefore">${name}</td><td class="xafter">${after}</td></tr>`;
	});

	return `<table class='xmodal-changes'>${html}</table>`;
}

function checkLocalStorage(key) {
	///////////////////////////////////////////////////////////////////////////////////////////
	// description: checks the localStorage to determine if an item exits and is not expired //
	///////////////////////////////////////////////////////////////////////////////////////////

	// if the localstore has the type
	if (localStorage.getItem(key)) {
		var localcache = JSON.parse(localStorage.getItem(key));

		// if the data has been cached for less than 1 day (86400000 ms)
		if (Date.now() - localcache.timestamp < 86400000) {
			// return the localstorage data
			return localcache;
		}
	}

	// return as false if localstorage check fails
	return false;
}

async function getLenders() {
	var localCache = checkLocalStorage(`cache.Lenders`);

	// if lender data is stored in localstorage
	if (localCache) {
		store.state.types["Lenders"] = localCache.data;
		return localCache.data;
	} else {
		await axios(`/api/lenders`, { require: "json" })
			.then((response) => {
				if (Array.isArray(response.data) && response.data.length > 0) {
					// add the data to localstorage
					localStorage.setItem(
						`cache.Lenders`,
						JSON.stringify({ timestamp: Date.now(), data: response.data })
					);

					store.state.types["Lenders"] = response.data;
					return response.data;
				}
			})
			.catch((error) => {
				console.log(`API Error: /api/lenders`, error);
				return false;
			});
	}
}

async function getSchools() {
	var localCache = checkLocalStorage(`cache.Schools`);

	// if lender data is stored in localstorage
	if (localCache) {
		store.state.types["Schools"] = localCache.data;
		return localCache.data;
	} else {
		await axios(`/api/schools`, {
			method: "POST",
			require: "json",
			data: {},
		})
			.then((response) => {
				if (Array.isArray(response.data) && response.data.length > 0) {
					// add the data to localstorage
					localStorage.setItem(
						`cache.Schools`,
						JSON.stringify({ timestamp: Date.now(), data: response.data })
					);

					store.state.types["Schools"] = response.data;
					return response.data;
				}
			})
			.catch((error) => {
				console.log(`API Error: /api/schools`, error);
				return false;
			});
	}
}
async function getEnrollmentTerms() {
	const cacheKey = "cache.EnrollmentTerms";

	var localData = checkLocalStorage(cacheKey);

	if (localData) {
		store.state.types["EnrollmentTerms"] = localData.data;
	} else {
		await axios
			.get(`/api/loanservice/enrollmentterms`)
			.then((response) => {
				localStorage.setItem(
					cacheKey,
					JSON.stringify({
						timestamp: Date.now(),
						data: response.data,
					})
				);

				store.state.types["EnrollmentTerms"] = response.data;
			})
			.catch((error) => {
				console.log(error);
				return false;
			});
	}
}

async function getType(type) {
	var localData = checkLocalStorage(`cache.${type}`);

	// if the type is stored in localstorage
	if (localData) {
		store.state.types[type] = localData.data;
	} else {
		await axios(`/api/loantypes/${type}`, { require: "json" })
			.then((response) => {
				// add the data to localstorage
				localStorage.setItem(
					`cache.${type}`,
					JSON.stringify({ timestamp: Date.now(), data: response.data })
				);
				store.state.types[type] = response.data;
			})
			.catch((error) => {
				console.log(`API Error: /api/types/${type}`, error);
				return false;
			});
	}
}

async function getSchoolType(type) {
	try {
		const localData = checkLocalStorage(`cache.${type}`);

		if (localData) {
			store.state.types[type] = localData.data;
		} else {
			const response = await axios.get(`/api/schooltypes/${type}`);

			localStorage.setItem(
				`cache.${type}`,
				JSON.stringify({ timestamp: Date.now(), data: response.data })
			);

			store.state.types[type] = response.data;
		}
	} catch (error) {
		console.log(`API Error: /api/schooltypes/${type}`, error);
		return false;
	}
}

function cleanSearchText(text) {
	// remove all characters which are not a letter, number, space, period, comma, apostrophe, hyphen (then remove double spaces)
	return text;
	//? text.replace(/[^a-zA-Z0-9 .,'-]/g, "").replace(/\s+/g, " ")
	//: "";
}

async function getSearchParameters(searchText, searchFilters, pageNumber) {
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// description: this takes the searh text and all active filters and outputs parameters for the url and post body //
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	var parameters = {};
	var searchType = null;

	// if the user is searching
	if (searchText) {
		// make sure the user has typed at least 3 alphanumeric characters, and less than 200
		if (
			searchText?.replace(/[^0-9a-z]/gi, "").length >= 3 &&
			searchText?.length <= 200
		) {
			// if the user is searching by name [the search text will contain a letter as the first character]
			if (searchText.charAt(0).match(/[a-z]/i)) {
				// set the search type to name
				searchType = "Name";
				parameters["SearchType"] = 1;
				parameters["SearchTerms"] = searchText.split(" ");

				// if the user is searching by ssn [the search text will validate with the isValidSSN function]
			} else if (isValidSSN(searchText)) {
				// set the search type to ssn
				searchType = "SSN";
				parameters["SearchType"] = 3;
				parameters["SearchTerm"] = searchText;

				// if the user is searching by application id [the search text will contain an amount of numbers not equal to 9]
			} else {
				// set the search type to application id
				searchType = "Application ID";
				parameters["SearchType"] = 2;
				parameters["SearchTerm"] = searchText;
			}
		} else {
			return;
		}
	}

	// if search filters are set
	if (searchFilters) {
		// loop through each of the filters
		searchFilters.forEach((section) => {
			// for each of the fields inside that filter
			section.results.forEach((field) => {
				// if the field has children
				if (field.children) {
					// for each of the children which are checked
					field.children
						.filter((child) => child.checked)
						.forEach((child) => {
							// check if the parameter exists, otherwise create it as a blank array
							parameters[section.param] = parameters[section.param] || [];

							// append the value to the parameter
							parameters[section.param].push(child.id);
						});

					// else if the field is checked
				} else if (field.checked && field.id != "skip") {
					// check if the parameter exists, otherwise create it as a blank array
					parameters[section.param] = parameters[section.param] || [];

					// append the value to the parameter
					parameters[section.param].push(field.id);
				}
			});
		});
	}

	// if there is a pagenumber
	if (pageNumber > 1) {
		parameters["PageNumber"] = pageNumber;
	}

	// return the search parameters
	return { parameters: parameters, searchType: searchType };
}

async function getLoans(searchText, searchFilters) {
	//////////////////////////////////////////////////////////////
	// description: searches for loans by name, ssn, or loan id //
	//////////////////////////////////////////////////////////////

	var parameters = {};
	var searchType = null;

	// if the user is searching
	if (searchText) {
		// make sure the user has typed at least 3 alphanumeric characters, and less than 200
		if (
			searchText?.replace(/[^0-9a-z]/gi, "").length >= 3 &&
			searchText?.length <= 200
		) {
			// if the user is searching by name [the search text will contain a letter as the first character]
			if (searchText.charAt(0).match(/[a-z]/i)) {
				// set the search type to name
				searchType = "Name";
				parameters["names"] = searchText.split(" ");

				// if the user is searching by ssn [the search text will validate with the isValidSSN function]
			} else if (isValidSSN(searchText)) {
				// set the search type to ssn
				searchType = "SSN";
				parameters["ssn"] = searchText;

				// if the user is searching by application id [the search text will contain an amount of numbers not equal to 9]
			} else {
				// set the search type to application id
				searchType = "Application ID";
				parameters["referenceId"] = searchText;
			}
		} else {
			return;
		}
	}

	// if search filters are set
	if (searchFilters) {
		// loop through each of the filters
		searchFilters.forEach((section) => {
			// for each of the fields inside that filter
			section.results.forEach((field) => {
				// if the field has children
				if (field.children) {
					// for each of the children which are checked
					field.children
						.filter((child) => child.checked)
						.forEach((child) => {
							// check if the parameter exists, otherwise create it as a blank array
							parameters[section.param] = parameters[section.param] || [];

							// append the value to the parameter
							parameters[section.param].push(child.id);
						});

					// else if the field is checked
				} else if (field.checked && field.id != "skip") {
					// check if the parameter exists, otherwise create it as a blank array
					parameters[section.param] = parameters[section.param] || [];

					// append the value to the parameter
					parameters[section.param].push(field.id);
				}
			});
		});
	}

	return await axios(`/api/loanservice/loans/filter`, {
		method: "POST",
		require: "json",
		data: parameters,
		searchType: searchType,
		postTime: Date.now(),
	})
		.then((response) => {
			// make sure the loan data is not empty
			if (
				response.data?.data?.length > 0 &&
				Array.isArray(response.data?.data)
			) {
				// for each of the loans
				response.data.data.forEach((application) => {
					application.borrowers.forEach((borrower) => {
						// if this is the primary borrower
						if (borrower.typeId === 1) {
							// set the full name on the borrower
							application.name = borrower.firstName
								? `${decodeHTMLEncodedStr(
										borrower.firstName
								  )} ${decodeHTMLEncodedStr(borrower.lastName)}`
								: "No Name";

							// set the initials of the borrower
							application.initials = `${borrower.firstName?.charAt(0) || ""}${
								borrower.lastName?.charAt(0) || ""
							}`.trim();
						}
					});

					// if the lenders api has already loaded
					if (store.state.types["Lenders"]) {
						// for each of the lenders that matches the current application's lenderId
						store.state.types["Lenders"]
							.filter((lender) => lender.id == application.lenderId)
							.forEach((lender) => {
								// set the application lenderName
								application.lenderName = decodeHTMLEncodedStr(lender.name);
							});
					}

					if (searchType) {
						// set the type of search
						application.searchCategory = "Loan";

						// if the user is searching by name
						if (response.config.searchType == "Name") {
							// create an array of names by splitting on the space
							var searchNames = searchText.split(" ");

							["name", "nameCoApplicant"].forEach((name) => {
								// if the name or namecoapplicant exists
								if (application[name]) {
									// loop through each of the search names, sorted by longest search name
									searchNames
										.sort((a, b) => b.length - a.length)
										.forEach((searchName) => {
											// create a regular expression to match the search text ('ig' means [case insensitve] [match all])
											var match = new RegExp(`(${searchName})`, "ig");

											// replace the matched text with empty html tags <></> surrounding it
											application[name] = application[name].replace(
												match,
												`<>$1</>`
											);
										});

									// replace all empty tags <></> with <span class='xhighlight'></span> to highlight the search terms in html
									application[name] = application[name]
										.replace(/<>/g, "<span class='xhighlight'>")
										.replace(/<\/>/g, "</span>");
								}
							});

							// if the user is searching by application id
						} else if (response.config.searchType == "Application ID") {
							// create a regular expression to match the search text ('ig' means [case insensitve] [match all])
							var match = new RegExp(`(${searchText})`, "ig");

							// replace the matched text with a span to highlight it
							application.searchNotification =
								"ID: " +
								application.referenceId.replace(
									match,
									`<span class="xhighlight">$1</span>`
								);

							// if the user is searching by ssn
						} else if (response.config.searchType == "SSN") {
							// highlight the ssn search text
							application.searchNotification = `SSN: <span class="xhighlight">${searchText}</span>`;
						}
					}
				});
			}

			return response;
		})
		.catch((error) => {
			console.log(`API Error: /api/loanservice/loans/filter/`, error);
			return false;
		});
}

async function getDraws(searchText, searchFilter, pageNumber, pageSize) {
	//const isAdmin = store.state.oidcStore.user.school_access;
	//const pageIndex = pageNumber - 1;
	const request = {
		search: searchText,
		drawStatusIds: searchFilter ? searchFilter.drawStatusIds : [], //isAdmin ? null : 2,
		enrollmentTermIds: searchFilter ? searchFilter.enrollmentTermIds : [],
		certificationMethodIds: searchFilter
			? searchFilter.certificationMethodIds
			: [],
		page: {
			index: pageNumber,
			size: pageSize,
		},
	};

	//searchFilter.forEach((filter) => {
	//	if (filter.param === "EnrollmentTerms" && filter.checked) {
	//		request.enrollmentTerm = filter.id;
	//	} else if (filter.param === "CertificationMethod" && filter.checked) {
	//		request.certificationMethod = filter.id;
	//	} else if (filter.param === "DrawStatus" && filter.checked) {
	//		request.drawStatus = filter.id;
	//	}
	//});

	return await axios
		.post(`/api/loanservice/draws`, request)
		.then((response) => {
			// make sure the loan data is not empty
			if (
				response.data?.items?.length > 0 &&
				Array.isArray(response.data?.items)
			) {
				// for each of the loans
				response.data.items.forEach((application) => {
					// add a showDetails value for each result
					application.showDetails = false;

					// set the full name on the borrower
					application.name = application.borrower.firstName
						? `${decodeHTMLEncodedStr(
								application.borrower.firstName
						  )} ${decodeHTMLEncodedStr(application.borrower.lastName)}`
						: "No Name";

					// set the initials of the borrower
					application.initials = `${
						application.borrower.firstName?.charAt(0) || ""
					}${application.borrower.lastName?.charAt(0) || ""}`.trim();

					// format the dates to YYYY-MM-DD
					application.loanPeriodStartDate =
						application.loanPeriodStartDate?.split("T")?.[0] || "";
					application.loanPeriodEndDate =
						application.loanPeriodEndDate?.split("T")?.[0] || "";

					application.anticipatedGraduationDate =
						application.anticipatedGraduationDate?.split("T")?.[0] || "";

					application.schoolCertificationDate = moment(
						application.schoolCertificationDate
					)?.format("YYYY-MM-DD");

					application.certificationMethodId =
						application.certificationMethod.id;

					// if the lenders api has already loaded
					if (store.state.types["Lenders"]) {
						// for each of the lenders that matches the current application's lenderId
						store.state.types["Lenders"]
							.filter((lender) => lender.id == application.lenderId)
							.forEach((lender) => {
								// set the application lenderName
								application.lenderName = decodeHTMLEncodedStr(lender.name);
							});
					}
				});
			}

			return response;
		})
		.catch((error) => {
			console.log(`API Error: /api/loanservice/loans/filter/`, error);
			return false;
		});
}

async function getDisbursements(
	searchText,
	searchFilters,
	pageNumber,
	pageSize
) {
	const request = {
		search: searchText,
		disbursementTypeIds: searchFilters ? searchFilters.disbursementTypeIds : [],
		disbursementStatusIds: searchFilters
			? searchFilters.disbursementStatusIds
			: [],
		enrollmentTermIds: searchFilters ? searchFilters.enrollmentTermIds : [],
		page: {
			index: pageNumber,
			size: pageSize,
		},
	};

	return await axios
		.post(`/api/loanservice/disbursements/filter`, request)
		.then((response) => {
			// make sure the loan data is not empty
			if (
				response.data?.items?.length > 0 &&
				Array.isArray(response.data?.items)
			) {
				// for each of the loans
				response.data.items.forEach((application) => {
					// add a showDetails value for each result
					application.showDetails = false;

					// check if borrower exists
					if (application.borrower) {
						// set the full name on the borrower
						application.name = application.borrower.firstName
							? `${decodeHTMLEncodedStr(
									application.borrower.firstName
							  )} ${decodeHTMLEncodedStr(application.borrower.lastName)}`
							: "No Name";

						// set the initials of the borrower
						application.initials = `${
							application.borrower.firstName?.charAt(0) || ""
						}${application.borrower.lastName?.charAt(0) || ""}`.trim();
					} else {
						application.name = "No Name";
						application.initials = "";
					}
					// format the dates to YYYY-MM-DD
					application.loanPeriodStartDate =
						application.loanPeriodStartDate?.split("T")?.[0] || "";
					application.loanPeriodEndDate =
						application.loanPeriodEndDate?.split("T")?.[0] || "";

					// set the disbursement status to false for all statuses except 3 and 4 (on hold)
					application.disbursementStatusCheckbox = [3, 4].includes(
						Number(application.disbursementStatusTypeId)
					)
						? true
						: false;

					// set the amount
					application.amount = application.certifiedAmount;

					application.anticipatedGraduationDate =
						application.anticipatedGraduationDate?.split("T")?.[0] || "";

					// if the lenders api has already loaded
					if (store.state.types["Lenders"]) {
						// for each of the lenders that matches the current application's lenderId
						store.state.types["Lenders"]
							.filter((lender) => lender.id == application.lenderId)
							.forEach((lender) => {
								// set the application lenderName
								application.lenderName = decodeHTMLEncodedStr(lender.name);
							});
					}
				});
			}

			return response;
		})
		.catch((error) => {
			console.log(`API Error: /api/loanservice/loans/filter/`, error);
			return false;
		});
}

function objectToUrlString(parameters) {
	var finalUrl = "";

	Object.entries(parameters).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			// add to the final url as a string split by commas
			finalUrl += `${key}=[${value.map((value) => `"${value}"`).join(",")}]&`;
		} else {
			finalUrl += `${key}=${value}&`;
		}
	});

	return finalUrl.slice(0, -1);
}

async function logPageView(page) {
	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	// description: logs a record of recent pageviews in localStorage and automatically removes duplicates //
	/////////////////////////////////////////////////////////////////////////////////////////////////////////

	var recentlyViewed = [];

	// if the localstore has any recently viewed pages
	if (localStorage.getItem("cache.RecentlyViewedPages")) {
		// retreive the data
		recentlyViewed = JSON.parse(
			localStorage.getItem("cache.RecentlyViewedPages")
		);
	}

	// remove all entries which match the current id/page
	recentlyViewed = recentlyViewed.filter(
		(entry) => entry.id !== page.id || entry.page !== page.page
	);

	// add the current page to the beginning of the array
	recentlyViewed.unshift(page);

	// add the data to localstorage
	localStorage.setItem(
		"cache.RecentlyViewedPages",
		JSON.stringify(recentlyViewed)
	);
}

function objectToHtmlTable(obj) {
	var html = "<table>";
	for (var key in obj) {
		var item = obj[key];
		var value =
			typeof item === "object"
				? objectToHtmlTable(item)
				: item?.toString() || "";
		html += "<tr><td>" + key + "</td><td>" + value + "</tr>";
	}
	html += "</table>";
	return html;
}

function openModal(data) {
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// description: opens a modal (the component is '@/components/Modal.vue', and it's included in @/views/layout/Page.vue) //
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// close open modals
	store.state.modal.active = false;

	// open this modal
	store.state.modal.active = true;
	store.state.modal.title = data.title || data;
	store.state.modal.description = data.description || "";
	store.state.modal.html = data.html || "";
	store.state.modal.buttons = data.buttons || "";
}

function closeModal() {
	/////////////////////////////////
	// description: closes a modal //
	/////////////////////////////////

	store.state.modal.active = false;
}

// export the following functions
export default Object.assign(
	{},
	{
		capitalize,
		checkFormChanges,
		checkLocalStorage,
		cleanSearchText,
		closeModal,
		compareObjects,
		displayProductType,
		getDisbursements,
		getDraws,
		getLenderName,
		getLenders,
		getLoans,
		getSchools,
		getSearchParameters,
		getType,
		isValidSSN,
		logPageView,
		objectToHtmlTable,
		objectToUrlString,
		openModal,
		phoneFormat,
		ssnFormat,
		getSchoolType,
		getEnrollmentTerms,
	}
);
