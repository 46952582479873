<template>
	<Loader :stretch="true" :inverse="true"></Loader>
</template>

<script>
	import { defineComponent } from 'vue';
	import { mapActions } from 'vuex';
	import Loader from '../components/Loader.vue';

	export default defineComponent({
		name: 'OidcCallback',
		components: { Loader },
		methods: {
			...mapActions('oidcStore', ['oidcSignInCallback']),
		},
		mounted() {
			this.oidcSignInCallback()
				.then((redirectPath) => {
					this.$router.push(redirectPath);
				})
				.catch((err) => {
					console.error(err);
					this.$router.push('/oidc-callback-error'); // Handle errors any way you want
				});
		},
	});
</script>
